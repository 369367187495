import React from "react"

import "../../assets/css/sectionBenefit.css"
export default function SectionBenefits() {
    return (
        <div className="container-fluid section-benefit">
            <div class="row ">
                <div class=" introCov col-sm-6  col-md-6 col-lg-6 ">
                    <div className="intro">
                        <h5 class="text-center"><strong> Benefits For Users</strong> </h5>
                       <ul >
                            <li>Verified contracts</li>
                            <li>Verified service provider</li>
                            <li>Secure payment structures</li>
                            <li>Networking and marketing</li>
                       </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-md-6  col-lg-6 bg-right ">
                    <div class="bg " />
                </div>
            </div>{" "}
        </div>
    );
}
