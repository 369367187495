
import React from "react";
import { useHistory } from "react-router";

// reactstrap components
import {
    

    Container,
    Row,
    Col,

} from "reactstrap";
import "../../assets/css/sectionWhoWeAre.css"
import AppPicture from "../../assets/img/Phone.png"

export default function WhoAreWe() {
    const history = useHistory();
    const handleClick = () => {
        history.push('/AboutUs')
    }
    return (

        <Container className="who" >
            <div className="whoare">

                <Row className=" justify-content-between align-items-center">
                    <Col className=" who-p mb-5 mb-lg-0 float-lg-right" lg="5">
                        <div class="whoIntro">
                            <h1 className="text-#FFC000 font-weight-extra-bold" >
                              <strong>  WHO WE ARE</strong>
                            </h1>
                            <p className="text-black mt-10" >
                                Apivoka is an innovative automation application specifically designed for the Logistic, Mining, Agriculture as well as the Construction industries</p>
                            <p className="text-black mt-10" >  Equipment for hire or trucks for hire, vans or bakkies can be easily accessible at the tip of your fingers without any complicated procedures.
                                We ease the stress, cost and save time for our clients.

                            </p>
                            <div class=" whoButton text-center">
                                <button type="button" class="btn btn-warning bounce-out-on-hover" onClick={handleClick}>Explore More</button>

                            </div>
                        </div>
                    </Col>
                    <Col lg="4" className="mt-5">
                        <div class="whoPic">
                            <img src={AppPicture} styles={{ height: "2rem" }} alt="" />
                        </div>

                    </Col>
                </Row>
            </div>
        </Container>

    );
}
