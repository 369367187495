import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick13 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Reporting accidents or irregular behaviour</h6>
            <p>Users have an option to cancel any service requested; however
                this can be done in two possible scenarios;<ul><li>The user should cancel the service before confirming the
                    service and service provider.</li>
                    <li>The user may cancel a requested service that’s already
confirmed but will charged a cancelation fee that is
equivalent to 20% of the initial price quote of the service
cancelled.</li></ul></p>
            <p>Should users encounter problems trying to cancel a requested
                service please contact Apivoka support chat through email for
                assistance.</p>

</div>
        </div>
    );
}