import React from "react";
import {Link} from "react-router-dom"
import "../../assets/css/freight.css"


function Freight (){
  return (
    
    <div className="freightCover" >
      
      <div className="freightContent">
       
        <div className="head"><h1><strong><span>Truck</span></strong>s</h1>
          <p>
            The trucking business is a very profitable part of the logistic industry in 
          Africa. Trucking is evidently essential to the performance of the economy in South Africa, having it being an inter link to different industrial sectors within South Africa.
           </p><p> Through our platform an ecosystem is created that links all for industries through the means of mass quantity transportation (trucks) all industries inter link. Trucking companies or private individual owners can simply list their trucks onto our data base for free.
            It is a fact that, our platform is a catalyst to accelerate development within the industry, but more importantly is introduce a new way of doing things through the means of technology.
            </p><p>Users on the platform can simply request for hire trucks that suit and will enable our customers to execute their short term and long term contracts of transportation.

        </p>
          <div class="col-md-12 text-center freightButton">
            <button type="button" style={{ marginRight: "1.2rem" }} className="btn " >List Logistic Vehicles</button>
            <Link to="/Coming">   <button type="button" className="btn" >Register to Apivoka</button></Link>
          </div></div>
        <div />
      </div>
      </div>
  );
}

export default React.memo(Freight);