import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick2 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Tipping the service provider</h6>
            <p>At the end of each service rendered, consumers can opt to
                award the service provider with a tip as a token of appreciation
                for the services rendered. Consumer’s can give the service
                provider a rating from 4 to 5 stars, the option will be made
                available to tip the service provider. Select the amount of
                choice.</p>
            <p>Tipping is not compulsory but rather an optional choice from the
                consumer, however consumers can skip this option, by rating
                the service provider and leaving tip option on “0” and click done
                to complete the process.</p>

            <p>Should users encounter a problem when trying to tip the
                service provider, please directly contact Apivoka support chat
                through email.</p>

</div>
        </div>
    );
}