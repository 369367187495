import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

//core components
import Footer from "components/Footer/Footer.js";

//sections
import Home1 from "../components/Home/Home1.js"
import ContactUs from "../components/ContactUS/ContactUs.js"
import Freight from "../components/Trucks/Freight.js"
import Mining from "components/Mining/Mining.js";
import AboutUs from "../components/AboutUs/AboutUs.js"

import ScrollRestoration from 'react-scroll-restoration'
import Privacy from "components/Legalities/Privacy.js"
import NavBar1 from "components/Navbars/NavBar1.js";

import Team from "components/OurTeam/Team.js";
import Construction from "components/Construction/Construction.js";
import Agriculture from "components/Agriculture/Agriculture.js";

import Services from "components/OurServices/Services.js";
import Coming from "components/Footer/Coming"
import ScrollButton from "components/Scroll/ ScrollButton.js";
import { Benefit } from "components/OurServices/Benefits.js";
import Facilitator from "components/Legalities/Facilitator.js";
import { Signing } from "components/Support/Signing/Signing.js";
import { Sign1 } from "components/Support/Signing/Sign1.js";
import HowItWorks from "components/AboutUs/HowItWorks.js";
import { Sign2 } from "components/Support/Signing/Sign2.js";
import { Sign3 } from "components/Support/Signing/Sign3.js";
import { Sign4 } from "components/Support/Signing/Sign4.js";
import { Support } from "components/Support/Support.js";
import { Payment } from "components/Support/Payment/Payment.js";
import { Profile } from "components/Support/Profile/Profile.js";
import { UPicktech } from "components/Support/UPicktech/UPicktech.js";
import { Pay1 } from "components/Support/Payment/Pay1.js";
import { Pay2 } from "components/Support/Payment/Pay2.js";
import { Pay4 } from "components/Support/Payment/Pay4.js";
import { Pay5 } from "components/Support/Payment/Pay5.js";
import { Pay6 } from "components/Support/Payment/Pay6.js";
import { Pay7 } from "components/Support/Payment/Pay7.js";
import { Pay8 } from "components/Support/Payment/Pay8.js";
import { Pay9 } from "components/Support/Payment/Pay9.js";
import { Pay10 } from "components/Support/Payment/Pay10.js";
import { Pay11 } from "components/Support/Payment/Pay11.js";
import { Pay12 } from "components/Support/Payment/Pay12.js";
import { Pay13 } from "components/Support/Payment/Pay13.js";
import { Pay14 } from "components/Support/Payment/Pay14.js";
import { Pay15 } from "components/Support/Payment/Pay15.js";
import { Pay16 } from "components/Support/Payment/Pay16.js";
import { Pay17 } from "components/Support/Payment/Pay17.js";
import { Pay18 } from "components/Support/Payment/Pay18.js";
import { UPick1 } from "components/Support/UPicktech/UPick1.js";
import { UPick2 } from "components/Support/UPicktech/UPick2.js";
import { UPick3 } from "components/Support/UPicktech/UPick3.js";
import { UPick4 } from "components/Support/UPicktech/UPick4.js";
import { UPick5 } from "components/Support/UPicktech/UPick5.js";
import { UPick6 } from "components/Support/UPicktech/UPick6.js";
import { UPick7 } from "components/Support/UPicktech/UPick7.js";
import { UPick8 } from "components/Support/UPicktech/UPick8.js";
import { UPick9 } from "components/Support/UPicktech/UPick9.js";
import { UPick10 } from "components/Support/UPicktech/UPick10.js";
import { UPick11 } from "components/Support/UPicktech/UPick11.js";
import { UPick12 } from "components/Support/UPicktech/UPick12.js";
import { UPick13 } from "components/Support/UPicktech/UPick13.js";
import { UPick14 } from "components/Support/UPicktech/UPick14.js";
import { Prof1 } from "components/Support/Profile/Prof1.js";
import { Prof2 } from "components/Support/Profile/Prof2.js";
import { Prof3 } from "components/Support/Profile/Prof3.js";
import { Prof4 } from "components/Support/Profile/Prof4.js";
import { Prof5 } from "components/Support/Profile/Prof5.js";
import { Prof6 } from "components/Support/Profile/Prof6.js";
import { Prof7 } from "components/Support/Profile/Prof7.js";
import Frequent from "components/Support/Frequent.js";
import Prof8 from "components/Support/Profile/Prof8.js";



const AppRouter = () => {
  return (
    <BrowserRouter>
      <ScrollRestoration />
      <div><NavBar1 />
        <div className="Cover ">

          <Switch>
            <Route path="/" exact component={Home1} />


            <Route path="/aboutUs" exact component={AboutUs} />
            <Route path="/heavy-equipemt-tippers-for-hire"  exact component={Services}/>

<Route path="/privacy" exact component={Privacy}/>
            <Route path="/facilitator" exact component={Facilitator} />
            <Route path="/trucks-for-hire" exact component={Freight} />
            <Route path="/benefit" exact component={Benefit} />
            <Route path="/construction-equipment-for-hire" exact  component={Construction} />
            <Route path="/farming-equipment-for-hire"  exact component={Agriculture} />
            <Route path="/mining-equipment-for-hire"  exact component={Mining} />
            <Route path="/contact-picktech"  exact component={ContactUs} />
            
<Route path="/Coming" exact component={Coming}/>
           <Route path="/signing" exact  component={Signing} />
            
            <Route path="/how-picktech-works" exact component={HowItWorks} />
            <Route path="/frequently-asked-questions" exact component={Frequent} />
            <Route path="/support-page" exact component={Support} />
            <Route path="/payments" exact component={Payment} />
            <Route path="/account" exact component={Profile} />
            <Route path="/how-picktech-work" exact component={Sign1} />
            <Route path="/create-account" exact component={Sign2} />
            <Route path="/assistance-signing-up" exact component={Sign3} />
            <Route path="/contact-details" exact component={Sign4} />
           {/*Payment and rate Routes*/}
            <Route path="/higher-prices-than-expected" exact component={Pay1} />
            <Route path="/additional-fees" exact component={Pay2} />
            <Route path="/unauthorised-charges" exact component={Pay4} />
            <Route path="/doubled-charged" exact component={Pay5} />
            <Route path="/Issues-referral-promotion-codes" exact component={Pay6} />
            <Route path="/price-calculation" exact component={Pay7} />
            <Route path="/issues-promotional-code" exact component={Pay8} />
            <Route path="/waiting-period-fee" exact component={Pay9} />
            <Route path="/cancelling-request" exact component={Pay10} />
            <Route path="/upfront-pricing" exact component={Pay11} />
            <Route path="/unsuccesful-card-payment" exact component={Pay12} />
            <Route path="/using-promotional-code" exact component={Pay13} />
            <Route path="/unable-supported-card" exact component={Pay14} />
            <Route path="/price-estimation" exact component={Pay15} />
            <Route path="/truck-machinery-calculator" exact component={Pay16} />
          
            <Route path="/removing-card" exact component={Pay18} />
            {/*Usining Apivoka compnent UPicktech*/}
            <Route path="/picktech-guide" exact component={UPicktech} />
            <Route path="/services-weren’t-rendered" exact component={UPick1} />
            <Route path="/tipping-service-provider" exact component={UPick2} />
            <Route path="/using-pickTech-safety-toolkit" exact component={UPick3} />
            <Route path="/requesting-service" exact component={UPick4} />
            <Route path="/changing-services" exact component={UPick5} />
            <Route path="/changing-location" exact component={UPick6} />
            <Route path="/calling-service-provider" exact component={UPick7} />
            <Route path="/having-technical-issue" exact component={UPick8} />
            <Route path="/rating-service" exact component={UPick9} />
            <Route path="/estimated-time-off-arrival" exact component={UPick10} />
            <Route path="/use-picktech-without-smartphone" exact component={UPick11} />
            <Route path="/reporting-accidents-irregular-behaviour" exact component={UPick12} />
            <Route path="/damage-cancellation-fees" exact component={UPick13} />
            <Route path="/cancelling-services-requested" exact component={UPick14} />
            {/*Usining Apivoka compnent UPicktech*/}
            <Route path="/unblocking-user-account" exact component={Prof1} />
            <Route path="/changing-user-email-address" exact component={Prof2} />
            <Route path="/changing-user-cell-phone-number" exact component={Prof3} />
            <Route path="/choosing-language" exact component={Prof4} />
            <Route path="/sign-up-for-newsletter" exact component={Prof5} />
            <Route path="/deactivating-user-account" exact component={Prof6} />
            <Route path="/resending-users-receipt" exact component={Prof7} />
            <Route path="/requesting-user-data" exact component={Prof8} />
            
>>>>>>> 3ea8be677232ef5bf40b63610e6e09c733dc0939
            <Redirect to ="/" exact />
          </Switch>
          <ScrollButton />
          <Footer />
          
        </div>


        <div className="all"></div>
      </div>
    </BrowserRouter>
  );
};

export default React.memo(AppRouter);
