import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay8 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Issues with a promotional code</h6>
            <p>
                In situation whereby users encounter problems using the
                promotional code ensure that the following;
                <ul>
                    <li>Check if the promotional code hasn’t expired and meets
                        all requirements.</li>
                    <li>Make sure the promotional code is rightfully applied.</li>
                    <li>Make sure you using the latest version.</li>
                </ul>
            </p>
            <p>Should users or service providers encounter any problems
                please do contact the Apivoka Contact Apivoka support chat
                through email.</p>

</div>


        </div>
    );
}