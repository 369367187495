import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Profile/prof1.css"


export const Prof4 = () => {
    return (
        <div className="container-fluid pro-cover">
            <SiList/>
            <div className="pro1-content">
            <h6>Choosing a language</h6>
            <p>Apivoka application gives you the possibility to change the
                existing language to the user’s choice of preference.</p>
            <p>Follow the guidance;<ul>
                <li>Tap on your profile main menu tab (top left corner)</li>
                <li>Select language</li>
                <li>Choose your choice of preference from the list provided.</li ></ul></p>
            <p>Should users encounter a problem with this process, you can
                simply directly contact Apivoka support chat through email.</p>
            
</div>
        </div>
    );
}