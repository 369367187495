import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay10 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Cancelling requested service</h6>
            <p>Cancellation charges are structured in order to compensate
                service providers for travelling to the requested location</p>
            <p>To cancel a requested;
                <ul>
                    <li>Users can cancel requests more than 3 minutes after
                        the service provider has accepted.</li>
                    <li>The service provider is permitted to cancel the accepted
                        request after 6 minutes of waiting.</li>
                    
                </ul>
            </p>
            <p>The cancellation charge notice appears on the Apivoka
                application and receipt of the fee charged is sent to the users
                email. However after the given time frame users may not
                cancel should they wish to do so, they will charged to pay the
                amount owed to the cancelled requested service.</p>
            <p>If users need any form assistance in regards this fee they can
                simply contact Apivoka support</p>



</div>

        </div>
    );
}