import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Profile/prof1.css"


export const Prof7 = () => {
    return (
        <div className="container-fluid pro-cover">
            <SiList/>
            <div className="pro1-content">
            <h6>Resending Users Receipt</h6>
            <p>After the requested service has been rendered, users will
                receive a receipt by email linked to the user’s Apivoka
                account. Users please pay attention that some receipts may be
                automatically located to a spam folder.</p>
            <p>To resend the receipt follow the guide;<ul>
                <li>Navigate to the main menu (top left corner)</li >
            <li >Select on ‘ride history’</li >
        <li >Tap on the needed ride</li >
                    <li >Click the resend receipt</li >
                    <li >Users will receive a confirmation that the receipt has been
                    sent.</li ></ul></p>

            <p>Should users not receive your receipts we recommend the
                following;<ul>
                    <li>Ensure that all the correct email address is connected with
                        the user’s Apivoka account. Changes the email if the
                        required and uses the option to resent the receipt again.</li >
                <li >Check the following rules set for your email address</li ></ul></p>
            

        </div>
        </div>
    );
}