import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay5 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Double charged for services</h6>
            <p>Apivoka performs a temporary card verification hold for every
                new card added to the system; this is done to check the
                legitimacy of the card being added.</p>
            <p>The hold of money is an authorisation process, the money is
                never charged to the users banking account but it will be
                enlisted as a pending charge that will be returned within 14
                business days. Should the money not reflect within the given
                period users are advised to communicate with their financial
                banking institution.</p>
            <p>Card verification hold can render to be unsuccessful due to the
                following possible reasons;<ul>
                    <li>There are insufficient funds in the account.</li>
                    <li>The bank doesn’t allow the transaction for particular
                        reasons.</li>
                    <li>The system picked up suspicious card activities.</li>
                    </ul></p>
            
            <p>Should it fail, users won’t be permitted to request services from
                the application.</p>
                <p>
                Should users or service providers encounter any problems
                please do contact Apivoka support chat through email.
                </p>
</div>
        </div>
    );
}