
import React from "react";


import "../../assets/css/section3.css"

import iconR from "../../assets/img/request.png"
import iconL from "../../assets/img/location.png"
import iconF from "../../assets/img/connect.png"
import iconR1 from "../../assets/img/free.png"
import iconB from "../../assets/img/booking.png"
import iconC from "../../assets/img/call.png"
import iconD from "../../assets/img/database1.png"
import iconA from "../../assets/img/available.png"



 function Section3() {
    return (

        <div className=" sec3 container" >
           
            <div class='container-fluid mx-auto mt-1 mb-5 col-12' style={{textAlign: "center"}}>
                <h2 > FE<span>ATUR</span>ES </h2>
                
                <div class="row mt-5" style={{justifyContent: "center"}}>
                    <div class="card  col-md-5 col-lg-4 col-sm-8   mb-5">
                        <div class="card-content">
                                <div class="card-body"> <img class="img-responsive rck" src={iconR}  alt="Tracking"/>
                                
                                <div class="card-title"> Realtime Tracking </div>
                                <div class="card-subtitle">
                                        <p> Real time tracking for monitoring purposes, users can track the location of the heavy
                                            equipment or logistical vehicle. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card  col-md-5 col-lg-4 col-sm-8    mb-5">
                        <div class="card-content">
                            <div class="card-body"> <img class="img-responsive rck" src={iconL} alt="Mapping"/>
                                    <div class="card-title"> Mapping</div>
                                <div class="card-subtitle">
                                        <p>  Mapping is automated to assist both consumers and service providers with traffic
                                        congestion. (Pick your location)   </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="row" style={{justifyContent: "center"}}>
                   
                    <div class="card col-md-5 col-lg-4 col-sm-8   mb-5">
                        <div class="card-content">
                            <div class="card-body"> <img class="img-responsive  rck" src={iconR1} alt="Access"/>
                                <div class="card-title">Unlimited Access to Market </div>
                                <div class="card-subtitle">
                                    <p> Get free and unlimited access to the digital ecosystem (with reliable market
                                        consumers) </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card col-md-5 col-lg-4 col-sm-8    mb-5">
                        <div class="card-content">
                            <div class="card-body"> <img class="img-responsive rck" src={iconF} alt="Access"/>
                                <div class="card-title"> Access To Equipment </div>
                                <div class="card-subtitle">
                                    <p> Easy access to any heavy equipment or logistic vehicle listed as can be requested with ease. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style={{justifyContent: "center"}}>
                    <div class="card col-md-5 col-lg-4 col-sm-8   mb-5">
                        <div class="card-content">
                            <div class="card-body"> <img class="img-responsive rck" src={iconB} alt="Booking"/>
                          
                                <div class="card-title"> Booking</div>
                                <div class="card-subtitle">
                                    <p> On demand logistic vehicles of heavy equipment booking.. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card col-md-5 col-lg-4 col-sm-8    mb-5">
                        <div class="card-content">
                            <div class="card-body"> <img class="img-responsive rck" src={iconD} alt="Network"/>
                                <div class="card-title"> Great Network</div>
                                <div class="card-subtitle">
                                    <p>  Unlimited reliable vast database with a great network.  </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="row" style={{ justifyContent: "center" }}>
                    <div class="card col-md-5 col-lg-4 col-sm-8   ">
                        <div class="card-content">
                            <div class="card-body"> <img class="img-responsive rck" src={iconA} alt="Reliability" />
                                
                                <div class="card-title"> Reliability</div>
                                <div class="card-subtitle">
                                    <p> Our platform is transparent and reliable. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card col-md-5 col-lg-4 col-sm-8  ">
                        <div class="card-content">
                            <div class="card-body"> <img class="img-responsive rck" src={iconC} alt="Call Center" />
                                <div class="card-title"> Call center</div>
                                <div class="card-subtitle">
                                    <p>   Call centre support system. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

                       

        </div>

    );
}

export default React.memo(Section3)