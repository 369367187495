import React from "react";
import "../../assets/css/sectionAbout.css";

 function About() {
  return (
    <div className=" about ">
      <div class="row ">
        <div class=" introCov col-sm-6  col-md-6 col-lg-6 ">
          <div className="intro">
            <h4 class="h4-seo">
              Solving logistical nightmares , using technology at the tip of
              your fingers .Choose Apivoka and ease your stress{" "}
            </h4>
            <h5 class="h5-seo">
              Technology is taking a twist as the world is upon the 4th
              industrial revolution. It is evident that technology can be opted
              as a catalytic tool to accelerate economic development, through
              solid positive inputs within the Mining, Agriculture, Logistics
              and the Construction industries.
            </h5>
            <span>
              Our solution driven and security oriented platform seeks to
              provide services from a pool of credible service providers, while
              maintaining a high level of customer satisfaction. Apivoka 's
              digital platform is an ecosystem designed to help businesses and
              individuals maneuver easily around their day to day operations,
              while cutting out the middleman and passing savings to our
              customers.
            </span>
          </div>
        </div>

        <div class="col-sm-6 col-md-6  col-lg-6 bg-right ">
          <div class="bg " />
        </div>
      </div>{" "}
    </div>
  );
}


export default React.memo(About);