import React from "react";

import "../../assets/css/benefits.css";
import SectionBenefits from "./SectionBenefits";
import service from "../../assets/img/service.webp";
import contract from "../../assets/img/contract.png";
import team from "../../assets/img/team.png";
import { UncontrolledTooltip, Button } from 'reactstrap';
import secure from "../../assets/img/secure.webp";

export const Benefit = () => {
  return (
    <div className="benefit-cover container-fluid">
      <SectionBenefits/>

      <section class="">
        <div class=" benefit-text">
          <div class="row mbr-justify-content-center ">
            <div class="col-lg-6 col-md-6 mb-5">
              <div class="wrap">
                <div class="ico-wrap">
                                  <span class="mbr-iconfont">  <img src={secure} alt="icon" /></span>
                </div>
                <div class="text-wrap vcenter">
                  <p class="mbr-fonts-style text1 mbr-text display-6">
                    {" "}
                    <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">
                      <strong>
                        Secure Payment Structures</strong>
                    </h2>
                    <p >    For both of our <strong>service providers </strong>and clients that seek
                      assistance in terms of <strong>contract facilitation </strong>.</p>
                    <Button  id="left">
                      Read <i class="fa fa-angle-double-right ml-2" />
                    </Button>{` `}
                    <UncontrolledTooltip placement="left" target="left" delay={0}>
                       Our system is
                      designed to ensure that every party involved in any project
                      receives remuneration accordingly to the consensus of any
                      agreement.
                    </UncontrolledTooltip>
                
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 mb-5">
              <div class="wrap">
                <div class="ico-wrap">
                  <span class="mbr-iconfont ">
                    <img src={service} alt="icon" />
                  </span>
                </div>
                <div class="text-wrap vcenter">
                  <p class="mbr-fonts-style text1 mbr-text display-6">
                    <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">
                      <strong> Verified Service Provider</strong>
                    </h2>
                    <p className="display-6">All service providers listed on our database undergo through 
                      <strong> inspection</strong> to ensure their <strong>credibility, reliability</strong> and
                      <strong> performance</strong> in terms of rendering <strong>services</strong>.</p>
                    <Button color="secondary" id="right">
                      Read
                      <i class="fa fa-angle-double-right ml-2" />
                    </Button>{` `}
                    <UncontrolledTooltip placement="right" target="right" delay={0}>
                       This is done to
                      ensure that our clients seeking services are met with
                      nothing but the best service at a convenience.
                    </UncontrolledTooltip>
                    
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 mb-5">
              <div class="wrap">
                <div class="ico-wrap">
                  <span class="mbr-iconfont  text-center ">
                    <img src={contract} alt="contract" />
                  </span>
                </div>
                <div class="text-wrap vcenter">
                  <p class="mbr-fonts-style text1 mbr-text display-6">
                    <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">
                      <strong> Verified Contracts</strong>
                    </h2>
                    <p>All <strong>clients</strong> using Apivoka 's PTY LTD <strong>contract facilitation </strong>
                     services are required to through a <strong> verification</strong> process</p>
                    <Button color="secondary" id="left2">
                      Read
                      <i class="fa fa-angle-double-right ml-2" />
                    </Button>{` `}
                    <UncontrolledTooltip placement="left" target="left2" delay={0}>
                      All clients using Apivoka 's PTY LTD contract facilitation
                      services are required to go through a verification process to
                      authenticate the legitimacy of the contract, this is put
                      into place to avoid fraudulent or any illegal dealings that
                      may arise and to protect the service provider's, client's
                      and the company's reputation.
                    </UncontrolledTooltip>
                    
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 mb-5">
              <div class="wrap">
                <div class="ico-wrap">
                  <span class="mbr-iconfont ">
                    <img src={team} alt="team" />
                  </span>
                </div>
                <div class="text-wrap vcenter">
                  <p class="mbr-fonts-style text1 mbr-text display-6">
                    <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">
                      <strong>Solution  Oriented</strong>
                    </h2>
                    <p>Our team is a <strong>youthful</strong>, <strong>knowledgeable</strong> team with the energy
                      and <strong>drive</strong> to put any effort into any situation that will
                      require a high level of <strong>technical strategizing</strong></p>
                    <Button color="secondary" id="right2">Read 
                      <i class="fa fa-angle-double-right ml-2"/>
                    </Button>{` `}
                    <UncontrolledTooltip className="tap" placement="right" target="right2" delay={0}>
                      <span class="strong">Our team is a youthful, knowledgeable team with the energy
                      and drive to put any effort into any situation that will
                        require a high level of technical strategizing</span> to ensure
                      smooth operations on project sites and also produce future
                      plans to ease the stress of our clients and service
                      providers.
                    </UncontrolledTooltip>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div />
    </div>
  );
};

