

import React from "react";
import { Link } from "react-router-dom";

import "../../assets/css/footer.css"




 function Footer() {
 
 

  
  
  return (
    <footer className="footer"  >
      <footer class="footer-section">
        <div class="container">
          <div class="footer-cta pt-5 pb-5">
            <div class="row">
              <div class="col-xl-3 col-md-3 mb-30">
                <div class="single-cta">
                  <i class="fas fa-info-circle"></i>
                  <div class="cta-text">
                    <h4><strong>Help and Support</strong></h4>
                    <span><Link to="/support-page">visit help centre</Link></span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 mb-30">
                <div class="single-cta">
                  <i class="fas fa-map-marker-alt"></i>
                  <div class="cta-text">
                    <h4><strong>Find us</strong></h4>
                    <span>Pretoria</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 mb-30">
                <div class="single-cta">
                  <i class="fas fa-phone"></i>
                  <div class="cta-text">
                    <h4><strong>Call us</strong></h4>

                    <span> <a href="tel:+27 82 782 8228">+27 82 782 8228</a></span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 mb-30">
                <div class="single-cta">
                  <i class="far fa-envelope-open"></i>
                  <div class="cta-text">
                    <h4><strong>Mail us</strong></h4>
                    <span><a href="mailto:Info@Apivoka.co.za">Info@Apivoka .com</a></span>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <div class="footer-content pt-5 pb-5">
            <div class="row">
              <div class="col-xl-4 col-lg-4 mb-50">
                <div class="footer-widget">
                  <div class="footer-widget-heading">
                    <h3><strong>Products</strong></h3>
                  </div>
                  <ul>
                    <Link to="/Coming"><span className=" list-serv   d-block  " >Apivoka </span></Link>
                    <Link to="/Coming">< span className="list-serv space  d-block ">Apivoka Cooperate</span></Link>
                    </ul>
                    <div class="footer-social-icon">
                    <div id="follow-us">
                  <h3>Follow us</h3>
                      <a href="https://www.facebook.com/picktechpvt"><i class="fa fa-facebook-square fa-lg"></i></a>
                      <a href="https://www.linkedin.com/company/picktechpvtltd"><i class="fa fa-linkedin-square fa-lg"></i></a>
                      <a href="https://twitter.com/Apivoka4"><i class="fa fa-twitter-square fa-lg"></i></a>
                      <a href="https://www.instagram.com/picktech_rsa/"><i class="fab fa-instagram fa-lg"></i></a>
                 
                    </div>
                  </div>
                  </div>
                </div>
              <div class="col-xl-4 col-lg-4 col-md-6 mb-30 use-link">
                  <div class="footer-widget">
                    <div class="footer-widget-heading ">
                    <h3><strong>Quick Links</strong></h3>
                    </div>
                    <ul style={{listStyleType:"none"}} >
                    <Link to="/">   <li className="list-serv">Home</li></Link>
                    <Link to="/trucks-for-hire"><li className="list-serv">Logistics</li></Link>
                   
                    <Link to="/aboutUs"> <li className="list-serv">About Us</li></Link>
               
                    <Link to="/mining-equipment-for-hire"><li className="list-serv">Mining Equipment</li></Link>
                    <Link to="/heavy-equipment-for-hire">  <li className="list-serv">Services</li></Link>
                  
                    
         

              
                
            <Link to="/farming-equipment-for-hire">  <li className="list-serv">Agriculture Equipment</li></Link>
                    <Link to="/contact-picktech"> <li className="list-serv">Contact Us</li></Link>
        
                    <Link to="/construction-equipment-for-hire"> <li className="list-serv">Construction Equipment</li></Link>
                    <Link to="/frequently-asked-questions"> <li className="list-serv">FAQs</li></Link>
                    <Link to="/how-picktech-works"> <li className="list-serv">How It Works</li></Link>
                      
                    </ul>
                  </div>
                </div>
              <div class="col-xl-4 col-lg-4 col-md-6  mb-30 use-link">
                  <div class="footer-widget">
                    <div class="footer-widget-heading">
                    <h3><strong>Subscribe</strong></h3>
                    </div>
                    <div class="footer-text mb-15">
                      <p className="text-white">Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                    </div>
                    <div class="subscribe-form">
                      <form className="news-form" action="#">
                        <input type="email" name="emaillist"placeholder="enter_email_address"/>
                      <button class="bttt" aria-label="Justify"><i class="fab fa-telegram-plane"></i></button>
                                </form>
                            </div>
                    </div>
                  </div>
            </div> 
              </div>
            </div>
            <div class="copyright-area">
              <div class="container">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 text-center col-md-6 text-md-left text-lg-left">
                    <div class="copyright-text">
                  <p class="powered">Copyright &copy; {new Date().getUTCFullYear()}, All Right Reserved <a href="https://codepen.io/anupkumar92/"><Link class="ml-1 powered" to="/">Apivoka </Link> {"  "}</a>Patented <span className="ml-3"><Link to="/Privacy" class="powered">Terms</Link></span></p>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6  col-md-6 text-md-right  d-lg-block text-right">
                <div class="footer-menu copyright-text">
                  <p><Link to="/Facilitator"><span class="mr-3 powered" style={{ color: "#ffc000" }}>Facilitation Agreement</span></Link><strong class="powered">Parented & Powered by Akıltech Inc </strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </footer>
    
    </footer>
    );
}

  export default React.memo(Footer)