import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay11 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Cancelling requested service</h6>
            <p>The upfront pricing system is used to show both the
                consumer and service provider a regulated, market related
                price before accepting the requested service.</p>
            <p>Users should note that the rate is calculated by adding the
                following;
                <ul>
                    <li>Duration for the working equipment.</li>
                    <li>In case of requesting heavy equipment a truck for
                        transportation may be required.</li>
                    <li>Base fare.</li>
                    <li>Kilometre rate.</li>
                    <li>Dynamic pricing.</li>
                    


                </ul>
            </p>
            <p>Should users change the initial location or service that will
                have a direct proportional change to the initial price set.</p>
            <p>For any inquiries in regards to any misunderstood prices users
                are advised to contact Apivoka support chat through email.</p>

</div>



        </div>
    );
}