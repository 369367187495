
import { Provider } from "react-redux";
import PropTypes from "prop-types";
import AppRouter from "../../router/AppRouter";
import createAppStore from "../../state/app/AppStore";


import "./App.css";






function App({ history }) {
 
  return (
    <Provider store={createAppStore()}>
     
      <AppRouter history={history} />
    </Provider>
  );
}

export default App;

App.propTypes = {
  history: PropTypes.object.isRequired,
};
