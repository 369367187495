import React from "react";
import "../../assets/css/servicesSection.css";

export const ServicesSection = () => {
  return (
    <div>
     

      <div class="services-section pd ">
        <div class="container ">
          <div class="text-c">
            <h6 class="title ">Services</h6>
          </div>
            <div class="row text-center ">
              <div class=" col-12 col-md-6 col-lg-6">
              <div class="square"><i class="fas fa-database"></i></div>
                <div class="serv">
                <h5><span>Request/List Heavy equipment</span></h5>
                  <p>Companies and individuals (private owners) in possession of
                    any heavy equipment that operates within the Mining,
                    Construction or Agriculture industry can list their heavy
                  equipment onto our database for free. The user (consumer) on
                    the platform is able to request any equipment listed on the
                    database for whichever needs at a number of cost-effective
                    options.</p>
                </div>
              </div>
              <div class=" col-12 col-md-6 col-lg-6 pull-right">
                
                <div class="square"><i class="fas fa-mobile-alt"></i></div>
              <div class="serv">
                <h5><span>Request/List Logistic Vehicle</span></h5>
                <p>Companies and individuals (private owners) in possession of
                  any logistical vehicle from large trucks to vehicles as small
                  as vans are welcome to list their vehicle onto our database
                  for free. The user (requester) on the platform is able to
                  request any logistic vehicle listed on the database at a
                  number of cost-effective options.</p>
                </div>
              </div>
              </div>
          <div class="row co text-center">
              <div class="col-md-6 col-lg-6 ">
                <div></div>
              <div class="square "><i class="fas fa-handshake"></i></div>
                <div class="serv">
                <h5><span>Contract facilitation</span></h5>
                <p>One of the main reasons the platform was created is to cater
                  for contract holders or subcontractors who don’t have the
                  required heavy equipment or logistic vehicles to execute the
                  project at hand. This will ensure that the person appointed
                  will be in a position to execute the project and reassure
                  their credibility.</p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
    </div>
  );
};
