import styled from 'styled-components';



export const Content = styled.div`
overflowY: scroll;
height: 40rem;
`;

export const Button = styled.div`
position: fixed;
width: 60%;
left:75%;
bottom: 40px;
height: 10px;
font-size: 2rem;
z-index: 1;
cursor: pointer;
color: #FFC000;
`
