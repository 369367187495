import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Profile/prof1.css"


export const Prof3 = () => {
    return (
        <div className="container-fluid pro-cover">
            <SiList/>
            <div className="pro1-content">
            <h6>Changing user’s cell phone numbers</h6>
            <p>UEvery user’s account is directly linked with the user’s phone
                numbers which cannot be changed. However users can use a
                new number on the application by creation a new account
                which will render the previous account to be a dormant account
                automatically.</p>
            <p>To open up a new account;<ul>
                <li>Access your profile under the main menu tab ( top left
corner)</li>
                <li>Tap on the logout icon (top right corner)</li>
                <li>Enter your new cell phone numbers and the confirmation
                    code sent to you from Apivoka via SMS.</li ></ul></p>
            <p>Should users encounter a problem with this process, you can
                simply directly contact Apivoka support chat through email.</p>
            <p>
                Users should note that the record of previous requests will not
                be recovered in the new profile.
            </p>

        </div></div>
    );
}