import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick5 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Changing services</h6>
            <p>Users have an option to change service providers or the
                particular service; however this has to be done before
                confirming the request for the service to be rendered should
                wish to change services after confirming a request for services
                to be rendered then users will have to pay a cancellation fee for
                the initial request before being enabled to request another
                service.</p>
            <p>Should users encounter a problem trying to change the service
                request, please directly contact Apivoka support chat through
                email for assistance.</p>

</div>
        </div>
    );
}