import React from "react"
import { Link } from "react-router-dom";

import Arrow from "../../../assets/img/right-arrow.png"
import "../../../assets/css/Profile/profile.css"
import { SiList } from "components/Buttons/SiList";

export const Profile = () => {
    return (
        <div className="container-fluid profile-cover">
            <SiList/>

       <div className="container">     <h2>Profile Account</h2>
            
           
                <p>Changing of user’s email address<Link to="/changing-user-email-address"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Sign up for newsletter<Link to="/sign-up-for-newsletter"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Changing user’s cell phone numbers<Link to="/changing-user-cell-phone-number"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Choosing a language<Link to="/choosing-language"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Deactivating user account<Link to="/deactivating-user-account"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Resending Users Receipt<Link to="/resending-users-receipt"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
            <p>Requesting user data<Link to="/requesting-user-data"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
            
            </div>

        </div>
    );
}