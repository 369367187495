import { SiList } from "components/Buttons/SiList";
import React from "react";
import "../../../assets/css/Profile/prof1.css";

export const Prof1 = () => {
  return (
    <div className="container-fluid pro1-cover">
      <SiList />
      <div className="pro1-content">
        <h6>Unblocking user’s account</h6>
       {/* <p>
          If you’re trying to order any item from the application, but you
          applications reflects a message that your user account has been
          blocked, do take note that the following could be possible reasons;
          <ul>
            <li> Multiple pending payments</li>
            <li>Using cards that have been flagged to be stolen or lost</li>
            <li>Abusing the “invite a friend code” sharing</li>
            <li>
              The suspected use of false personal information used to register
              as a user.
            </li>
          </ul>
        </p>
        */}
        <p>
          Please contact our support team via the Apivoka support chat and
          we’ll look into the situation at hand and give the best option in
          regards to the situation at hand.
        </p>
        <p>Contact Apivoka support chat through email.</p>
      </div>
    </div>
  );
};
