import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick11 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Use Apivoka without a smartphone</h6>
            <p>The general public without smart phones are still considered as
                our clients therefor they can access our services without being
                in possession of a smart phone.</p>
            <p>Please log onto the user account using the phone number on
                our mobile.site.</p>
            <p>However this option will only be applicable to users that will be
                able to settle the balance cash.</p>
            <p>Should users encounter any problems please contact Apivoka
                support chat through email for assistance.</p>
</div>

        </div>
    );
}