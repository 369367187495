import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Signing/sign1.css"

export const Sign4= () => {
    return (
        <div className="container-fluid s1-cover">
            <SiList/>
            <div className="s1-content">
            <h6>My contact details are already in use.</h6>
            <p>In cases whereby the email or cell phone number is already in use please
                contact the support team with the email and cell phone so the
                administration team will review the problem.</p>
            
        </div>
        </div>
    );
}