// IMPORT PACKAGE REFERENCES

import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

// IMPORT MIDDLEWARE
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import logger from "redux-logger";

// IMPORT REDUCERS

import { AppReducer } from "./AppReducer";
import errorMiddleware from "./errorMiddleware";

export const createAppStore = () => {
  const store = composeWithDevTools(
    applyMiddleware(errorMiddleware, thunk, promise, logger)
  )(createStore);
  return store(AppReducer);
};

export default createAppStore;
