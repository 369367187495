import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Profile/prof1.css"


export const Prof6 = () => {
    return (
        <div className="container-fluid pro-cover">
            <SiList/>
            <div className="pro1-content">
            <h6>Deactivating user account</h6>
            <p>Should a user wish to deactivate their account, please do
                enlighten us what the reason may be so we able to meet
                clientele satisfaction at all times.
                However should users wish to deactivate their accounts, we
                have made it easier for users, simply contact Apivoka support
                chat through email.</p>

                <p>After confirming to deactivate the user account we will not be
                able to restore it, all information linked with the account will be
                removed from our data base including credit card information
                stored or promotions.</p>
               <p> Please note that we may have deleted your data, was till keep
                information such as invoices and payment information to
                comply with the law as stated in our Privacy Policy.
                Should users encounter a problem with this process, you can
                simply directly contact Apivoka support chat through email.</p>

</div>
        </div>
    );
}