import React, {  useEffect } from "react"
import { NavLink ,Link} from "react-router-dom"
import "../../assets/css/nav.css"
import Logo from "../../assets/img/Pictech.png"


function NavBar1() {





    const [scrolled, setScrolled] = React.useState(false);
    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 200) {
            setScrolled(true);
        }
        else {
            setScrolled(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })


    let navbarClasses = ['navbar'];
    if (scrolled) {
        navbarClasses.push('scrolled');
    }

    const openNav = () => (document.getElementById("myNav").style.width = "100%");

    const closeNav = () => {

        document.getElementById("myNav").style.width = "0%";
    };


    return (
        <div>

            <div id="myNav" class="overlay1  ">

                <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>&times;</a>
                <div class="overlay-content">

                    <div className=" ">
                        <ul className="nav " style={{ display: "block" }}>
                            <li className="is-active">
                                <NavLink to="/" onClick={closeNav} replace>
                                    HOME
                                </NavLink>
                            </li>
                            <li className="is-active" onClick={closeNav}>
                                <NavLink to="/construction-equipment-for-hire" replace>

                                    CONSTRUCTION
                                </NavLink>
                            </li>
                            <li className="is-active">
                                <NavLink to="/farming-equipment-for-hire" onClick={closeNav} replace>
                                    AGRICULTURE
                                </NavLink>
                            </li>
                            <li className="is-active">
                                <NavLink to="/trucks-for-hire" onClick={closeNav} replace>
                                    LOGISTICS
                                </NavLink>
                            </li>
                            <li className="is-active">
                                <NavLink to="/mining-equipment-for-hire" onClick={closeNav} replace>
                                    MINING
                                </NavLink>
                            </li>
                            
                            <li className="is-active">
                                <NavLink to="/heavy-equipemt-tippers-for-hire" onClick={closeNav} replace>
                                    SERVICES
                                </NavLink>
                            </li>
                            <li className="is-active">
                                <NavLink to="/AboutUs" onClick={closeNav} replace>
                                    ABOUT US
                                </NavLink>
                            </li>

                            <li className="is-active">
                                <NavLink to="/how-picktech-works" onClick={closeNav} replace>
                                    How It Works
                                </NavLink>
                            </li>
                             {/*<li className="is-active">
                                <NavLink to="/picktech-team" onClick={closeNav} replace>
>>>>>>> 3ea8be677232ef5bf40b63610e6e09c733dc0939
                                    OUR TEAM
                                </NavLink>
                            </li>
                            */}

                            <li className="is-active">
                                <NavLink to="/contact-picktech" onClick={closeNav} replace>
                                    CONTACT
                                </NavLink>
                            </li>
                        </ul>
                    </div>


                </div></div >

            <nav className={scrolled ? 'navbar scrolled' : 'navbar'}  >
             <Link to="/">  <span className="logoContent">
                    <span style={{ display: "inline-block", margin: "0.5rem" }}>
                        <img className="logo" src={Logo} alt="" />
                    </span>
                    <span style={{ display: "inline-block", verticalAlign: "middle", lineHeight: "normal" }}>
                        <span style={{ display: "block" }}>
                           <strong> <span className="pick" style={{}}>API</span>
                                <span className="pick1" style={{}}>VOKA</span></strong>
                        </span>
                       
                    </span>

                </span>
                </Link>
                <div className="pull-right">
                    {/*  <span className="hide1" style={{ display: "inline-block", marginRight: "1rem", color: "#FFC000" }}>
                            <i
                                className="hide1 fa fa-phone fa-lg"
                                aria-hidden="true"
                            ><span className=" hide1" style={{ fontSize: "0.9rem",marginLeft:"0.2rem" ,verticalAlign: "middle", lineHeight: "normal"}}>+277 890 34657</span></i>
                        </span>
                        */}
                    <span className="hide1" style={{ display: "inline-block", marginRight: "1rem" }}>
                        <a href="https://www.facebook.com/picktechpvt">   <i
                            className="fa fa-facebook-square fa-lg nav-icon"
                            aria-hidden="true"
                        /></a>
                    </span>
                    <span className="hide1" style={{ display: "inline-block", marginRight: "1rem", color:"#FFC000" }}>
                        <i
                            className="fa fa-twitter-square fa-lg nav-icon-1"
                            aria-hidden="true"
                        />
                    </span>

                    <span className="hide1" style={{ display: "inline-block", marginRight: "1rem" }}>
                        <a href="https://www.linkedin.com/company/picktechpvtltd">  <i
                            className="fa fa-linkedin-square fa-lg nav-icon"
                            aria-hidden="true"
                        /></a>
                    </span>
                    <span
                        className=" menu "
                        
                        
                        
                        onClick={openNav}>
                        &equiv;</span>
                </div>
            </nav>


        </ div>


    )
}


export default React.memo(NavBar1);