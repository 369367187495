import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Signing/sign1.css"


export const Sign1=()=>{
    return(
<div className="container-fluid s1-cover">
    <SiList/>
            <div className="s1-content">
    <h6>How does Apivoka Work</h6>
    <p>Apivoka is a platform designed to automate the traditional way of doing
                things, this is evolving with with rapid growth of technology.</p>
    <p>Apivoka&#39;s model is simply less complicated, the platform is designed for
                contractors and service providers, people that own earth moving
                equipment, agricultural equipment or logistic vehicles are identified as
                service providers. They can register with Apivoka and have access to
                different types of contracts that are brought to the platform by contractors.</p>
    <p>The contractors are identified as clients will therefore submit their
                contracts on the platform requesting services to be rendered by registered
                and vetted service providers on our platform. The service provider has the
                option to choose between the various contracts and can choose of their
                choice.</p>
            
</div></div>
    );
}