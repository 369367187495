import React from "react"
import { Link } from "react-router-dom";
import Trip from "../../assets/img/destination.png"
import Account from "../../assets/img/credit-card-payment.png"
import Snp from "../../assets/img/sign-up.png"
import Guide from "../../assets/img/route.png"
import More from "../../assets/img/plus.png"
import Arrow from "../../assets/img/right-arrow.png"
import "../../assets/css/support.css"
import Suppp from "../../assets/img/supporting.jpg"
import { SiList } from "components/Buttons/SiList";

export  const  Support=()=>{
    return(
       <div className="container-fluid support-cover ">
           
            <div className="banner-breadcum section">
                <div className="row">
                    <div class="breadcrumb-image" style={{background: `url(${Suppp})`,color:'black'}}>
                            <h1 className="text-center text-black answ-int">We're here to answer your questions</h1>
                        
                        </div>
                    </div>
                </div>
            <SiList/>


           
            <div className="center support-inner">
                <div>
                <h2>All Topics</h2>
                    <p><img src={Guide} alt="A Guide to use Apivoka" />A Guide to use Apivoka<Link to="/picktech-guide"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                    <p><img src={Snp} alt="Signing Up" />Signing Up<Link to="/signing"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>

                <p><img src={Account} alt="Account and Payment Options" /> Payments and Rates<Link to="/payments"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p><img src={More} alt="More" />Profile Account and Data<Link to="/account"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                </div>
        </div>
            </div>

           
    );
}