
import React from "react";


import "../../assets/css/section1.css"



 function Section1() {
    return (
        
            <div className=" sec1 " >
                <div class="row phone-row">
                    <div class="col-sm-6  col-md-6  col-lg-6 bg-left " >
                    </div>

                <div class=" introCov1 col-sm-6 col-md-6  col-lg-6 ">
                    
                    <div class="intro1"> 
                        <span  >
                        Apivoka creates value through innovative and easy to use technology in the logistics,
                        agriculture and mining industries. With an ecosystem of vast services that are designed to
                        ease the mundane and complicated processes businesses go through when sourcing and
                        procuring logistics vehicles and/or heavy equipment. 
                    </span>
                    <p/>
                        <span>
                            Both logistic and
                            equipment owning companies or individuals have the ability to list their equipment or logistic
                            vehicles with us for free, giving access to a secured, reliable free market to operate from.
                        </span>
                    </div>
                    </div>


                </div>


            </div>

        );
}


export default React.memo(Section1);