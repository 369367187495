import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay16 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Price estimation</h6>
            <p>The price estimation’s purpose is to give users the idea of
                what the actual price might be when requesting the service
                from the service provider.</p>
            <p>Steps to follow in order to get price estimation;
                <ul>
                    <li>Navigate to the search destination plane</li>
                    <li>Enter the location as well as the destination.</li>
                            <li>Select the type of truck or machinery needed</li>
                    <li>The cost will reflect on the display screen</li>



                </ul>
            </p>
            

            <p>Should users or service providers encounter any problems
                please do contact Apivoka support chat through email.</p>


</div>
        </div>
    );
}