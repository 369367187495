import Section3 from "components/AboutUs/Section3";
import { SiList } from "components/Buttons/SiList";
import React from "react";
import "../../assets/css/services.css"

import { ServicesSection } from "./ServicesSection";


const Services = () => {

    return (
        <div className="services-page ">
            <header>
                <h1><strong>OUR SERVICES</strong></h1>
            </header>
            <p class="text-center our-intro">Apivoka 's platform caters for both, one time users and contract holders. Both users can
                simply request whichever heavy equipment or logistic vehicle needed. The platform is real
                time, live tracking and both the user and service provider have access to an ETA of the
                asset requested.</p>
              
            <Section3/>
          <SiList/>
            <ServicesSection />
            <div>


            </div>
        </div>
    )
}

export default React.memo(Services);