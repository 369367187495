import React from "react"
import { Link } from "react-router-dom";

import Arrow from "../../../assets/img/right-arrow.png"
import "../../../assets/css/signing.css"
import { SiList } from "components/Buttons/SiList";

export const Signing = () => {
    return (
       
        <div className="container-fluid signing-cover">
            <SiList/>
            
            <div className="sign-content container"><h2>Signing Up</h2>
            <p>How does Apivoka Work?<Link to="/how-picktech-work"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
            <p>How do I create a Apivoka account?<Link to="/create-account"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
            <p>I need assistance with signing up.<Link to="/assistance-signing-up"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
            <p>My contact details are already in use.<Link to="/contact-details"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
            
                </div>
        </div>
    );
}