/**
 * a utility to check if a value is a Promise or not
 * @param value
 */
const isPromise = (value) =>
  value !== null &&
  typeof value === "object" &&
  typeof value.then === "function";

export default function errorMiddleware() {
  return (next) => (action) => {
    // const types = {
    //   FOO: true,
    // };

    // If not a promise, continue on
    if (!isPromise(action.payload)) {
      return next(action);
    }

    /*
     * Another solution would would be to include a property in `meta`
     * and evaulate that property.
     *
     * if (action.meta.globalError === true) {
     *   // handle error
     * }
     *
     * The error middleware serves to dispatch the initial pending promise to
     * the promise middleware, but adds a `catch`.
     */
    // if (_.has(types, action.type)) {
    // Dispatch initial pending promise, but catch any errors
    return next(action).catch((error) => {
      // supress all rejections
      console.log(
        `-ERROR MIDDLEWARE-\n${action.type}_REJECTED with error: `,
        error
      );
      return error;
    });
    // }

    // return next(action);
  };
}
