import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick7 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Calling the service provider</h6>

            <p>In certain situations the user may need to contact the service
                provider, there the user can use the following guide;
                <ul>
                    <li>Navigate to the Main menu (top left corner of the main
                        screen).</li>
                    <li>Tap on the history of the service rendered.</li>
                    <li>Click the relevant trip.</li>
                    <li> Select the call option.</li>
                    </ul></p>

            <p>However users encounter problems during the process, please
                directly contact Apivoka support chat through email for
                assistance.</p>

</div>
        </div>
    );
}