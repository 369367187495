import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay18 = () => {
    return (
        <div className="container p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Removing a card</h6>
            <p>Should users desire to remove cards from the platform as
                one of the payment methods, follow the following steps;
                <ul><li>Navigate to main menu (top left corner)</li> 
                    <li>Tap payments</li>
                    <li>Swipe the line with a card number to the left</li>
                <li>The delete option will pop up to enable the user to
            remove the card.</li> </ul></p>
            <p>Should users or service providers encounter any problems
                please do contact Apivoka support chat through email.</p>

</div>

        </div>
    );
}