import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay15 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Adding or selecting a payment method of choice</h6>
            <p>Apivoka has three forms of payments, mainly cash, Apple
                Pay and card options before requesting services; however
                the payment option is automatically added.</p>
            <p>To add a card, simply follow this guide;
                <ul>
                    <li> Navigate to the main menu (top left corner)</li>
                    <li>Select payments</li>
                    <li>Click add card</li>
                                <li> Users can manually or scan the details of the card and
                        confirm</li>
                                    <li> Enter the required details of the card.</li>
                                        <li>Another form of payment could be made as a direct
                    deposit into the Apivoka 's FNB account.
                    </li>



                </ul>
            </p>
            <p>
                Apivoka performs a temporary card and verification to
                check out the legitimacy of the card being add to the
                platform, this is to minimize any possible fraudulent activities.
            </p>

            <p>Should users or service providers encounter any problems
                please do contact Apivoka support chat through email.</p>



        </div></div>
    );
}