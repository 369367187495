// IMPORT PACKAGE REFERENCES
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

// IMPORT REDUCERS

// EXPORT APP REDUCER
export const AppReducer = combineReducers({
  form: formReducer,
});

export default AppReducer;
