import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay1 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Price higher than expected</h6>
            <p>Users may incur additional fees on their Apivoka receipts.
                Please take note that these additional fees may be added due
                to requests are taken through major city gateways such as
                national tunnels or bridges.</p>
            <p>Due note the additional charges may be added also in regards
                to;<ul>
                    <li>Overlapped duration fee.</li>
                    <li>Waiting period fees.</li>
                    <li>Booking fees.</li>
                    <li>Toll gate fees.</li></ul></p>
            <p>Users should take note that these additional charges are
                applicable to both cash and card payments; hence they will be
                automatically added to the requested service.</p>
            <p>Should users or service providers encounter any problems
                please contact Apivoka support</p>
            </div>
        </div>
    );
}