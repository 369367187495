import React from "react"

import "../../assets/css/howitworks.css"
import cus1 from "../../assets/img/add.png"
import cl1 from "../../assets/img/add-file.png"
import cus3 from "../../assets/img/approved.png"
import cus4 from "../../assets/img/choose.png"
import cus5 from "../../assets/img/upload-file.png"

import cl2 from "../../assets/img/approved3.png"
import cl3 from "../../assets/img/approved2.png"
import cl4 from "../../assets/img/search.png"
import cl5 from "../../assets/img/uplod.png"
import cl6 from "../../assets/img/confirm-file.png"
import cl7 from "../../assets/img/storage.png"
import cl8 from "../../assets/img/paper-list.png"
import { SiList } from "components/Buttons/SiList"

export default function HowItWorks (){
    return (
        <div className="container-fluid app-work">
            <SiList/>
            <h2>How It Works</h2>
            <h3>Fleet and Equipment Owners</h3>
            <div className="row">
                
                <div className="col-sm it-work ">
                    <div className="register-icon"> <img src={cl1} alt="register" /></div>
                    <h6 className="center">Step 1</h6>
                    <p><a href="">Register</a> online on the official Apivoka website.
Submit all requested documentation.</p>
                </div>
                <div className="col-sm it-work ">
                    <div className="register-icon"> <img src={cl2} alt="register" /></div>
                    <h6 className="center">Step 2</h6>
                    <p>Apivoka will vet the application submitted and
                         upon approval, clients should be able log-in on the application and user
                        dashboard.</p>
                </div>
                <div className="col-sm it-work ">
                    <div className="register-icon"> <img src={cl3} alt="register" /></div>
                    <h6 className="center">Step 3</h6>
                    <p>Register the company with the user dashboard, to receive requests.
                        Upon log-in of the user dashboard, the client should be able to receive
                        requests.</p>
                </div>
                <div className="col-sm it-work ">
                    <div className="register-icon"> <img src={cl4} alt="register" /></div>
                    <h6 className="center">Step 4</h6>
                    <p>The client has the option to choose a contract of their choice from the
                        requests received.
                         The client can then execute the contract as per request.</p>
                </div>
                <div className="col-sm it-work ">
                    <div className="register-icon"> <img src={cl5} alt="register" /></div>
                    <h6 className="center mb-0">Step 5</h6>
                    <p>Upon completion of the requests of the contract the client is required to
                        submit PODs or proof of the work done as per request.</p>
                </div>
            </div>
            <div className="clie-op"/>
            <h3>Clients</h3>
            <div className="row ">

                <div className="col-sm it-work col-lg-3">
                    <div className="register-icon"> <img src={cus1} alt="register" /></div>
                    <h6 className="center">Step 1</h6>
                    <p><a href="/">Register </a>online by submitting all required relevant documentation.</p>
                </div>
                <div className="col-sm it-work  col-lg-3">
                    <div className="register-icon"> <img src={cus3} alt="register" /></div>
                    <h6 className="center">Step 2</h6>
                    <p>Apivoka team will vet the submitted application.
                     Upon approval customers will have access to the Mobile Application.</p>
                </div>
                <div className="col-sm it-work col-lg-3">
                    <div className="register-icon"> <img src={cus4} alt="register" /></div>
                    <h6 className="center">Step 3</h6>
                    <p>Once approved;the customer can log-in onto
                        the Mobile Application and beyond logging in, customers are able to select industry tab of their
                        choice.</p>
                </div>
                <div className="col-sm it-work col-lg-3">
                    <div className="register-icon"> <img src={cl6} alt="register" /></div>
                    <h6 className="center">Step 4</h6>
                    <p>Choose to make a long term or once off request ,fill in a Specifications document, then select the 'submit all'tab to complete
                        the request.</p>
                </div>
               
                <div className="col-sm it-work col-lg-3">
                    <div className="register-icon"> <img src={cl8} alt="register" /></div>
                    <h6 className="center">Step 5</h6>
                    <p>Confirm the payment terms ,verify the specifications of the request you
                        created,agree to the Terms and Conditions and agree to the Contract
                        Facilitation Agreement box tab.</p>
                </div>
               
                <div className="col-sm it-work col-lg-3">
                    <div className="register-icon"> <img src={cl7} alt="register" /></div>
                    <h6 className="center">Step 6</h6>
                    <p>Submit the request and wait for approval from
                        a relevant service provider from our database.</p>
                </div>
            </div>
        </div>
    )
}