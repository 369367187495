import React from "react";
import "../../assets/css/contactSection.css";
import SendEmail from "../../assets/img/sendmail.webp";
import emailjs from "emailjs-com";

function ContactSection() {
  const submitBtn = document.getElementById("submit-btn");

  const validate = (e) => {
    e.preventDefault();
    const sender = document.getElementById("sender");
    const email = document.getElementById("email");
    const phone = document.getElementById("phone");
    const message = document.getElementById("message");

    if (sender.value === "") {
      sender.focus();
      return false;
    }
    if (email.value === "") {
      email.focus();
      return false;
    }
    if (phone.value === "") {
      phone.focus();
      return false;
    }

    if (phone.value.length!==10) {
      document.getElementById("phone_error").classList.remove("hidden");
      message.focus();
      return false;
    }

    if (message.value === "") {
      message.focus();
      return false;
    }
    if (!emailIsValid(email.value)) {
      email.focus();
      return false;
    }

    return true;
  };
  const emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  if (submitBtn) {
    submitBtn.addEventListener("click", validate);
  }
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_sv0u3kh",
        "Apivoka_2021",
        e.target,
        "user_8mQebjn3c7USymhEQ7EHi"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div id="contact-page">
      <div class="container d-flex justify-content-center">
        <div class="row  ">
          <div class="col-md-4 col-sm-6 col-lg-4 mb-3 img-cont">
            {" "}
            <img src={SendEmail} alt="IMG" />
            <div class="get-us">
              <p class="justify  text-center contact-text">
                {" "}
                We believe that we are one with our clients so client
                satisfaction is our primary goal, we aim to be better, faster
                and cheaper than anyone else.
              </p>
              <p class="justify  text-center contact-text">
                <strong>
                  For listing of equipment and registarion enquiries Email/Call
                  Us{" "}
                </strong>{" "}
                <br />
                <span className="d-block">
                  <a href="mailto:Info@Apivoka.co.za">Info@Apivoka .com</a>{" "}
                </span>
                <span className="d-block">
                  <a href="mailto:lindamalabe@gmail.com">
                    lindamalabe@gmail.com
                  </a>{" "}
                </span>
                <span className="d-block">
                  <a href="mailto:mushaitapiwanashe@gmail.com">
                    mushaitapiwanashe@gmail.com
                  </a>{" "}
                </span>
                <span className="d-block">
                  <a href="tel:+27 82 782 8228">Call: +27 82 782 8228</a>{" "}
                </span>
              </p>
            </div>
          </div>

          <div class="col-md-8 col-lg-8 contact-info">
            <h2 class="form-title text-center">
              G<span className=" h-h">et in Touch With U</span>s
            </h2>
            <p class="justify  text-center contact-text get-touch">
              We are open to hearing your innovative suggestions, concerns and
              inquiries. Please feel free to fill out the form below!
            </p>
            <form onSubmit={sendEmail}>
              <div class="form-group pt-2 pl-1">
                {" "}
                <label for="exampleInputName">Your name</label>{" "}
                <input
                  type="text"
                  name="sender"
                  class="form-control form-field success"
                  required
                  minlength="3"
                  maxlength="20"
                  id="name"
                />{" "}
              </div>
              <div class="form-group pl-1">
                {" "}
                <label for="exampleInputEmail1">Your email address</label>{" "}
                <input
                  type="email"
                  name="email"
                  class="form-control form-field success email"
                  id="email"
                  required
                />{" "}
              </div>
              <div class="form-group pt-2 pl-1">
                {" "}
                <label for="exampleInputName">Your number</label>{" "}
                <input
                  type="tel"
                  name="phone"
                  class="form-control form-field success"
                  noValidate
                  id="phone"
                  required
                />{" "}
              </div>{" "}
              <div id="phone_error" class="error hidden">
                Please enter a valid phone number
              </div>
              <div class="form-group pl-1">
                {" "}
                <label for="exampleFormControlTextarea1">
                  Your message
                </label>{" "}
                <textarea
                  name="message"
                  type="text"
                  class="form-control form-field success"
                  noValidate
                  id="exampleFormControlTextarea1"
                  required
                  minlength="5"
                  maxlength="100"
                  rows="5"
                ></textarea>{" "}
              </div>
              <div class="row">
                <div class="col-md-12 text-center">
                  <button
                    type="submit"
                    name="submit"
                    class="btn submitbutton "
                    id="submit-btn"
                    value="Send"
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;

