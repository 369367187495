import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay2 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Additional fees</h6>
            <p>Users are able to get price estimation before requesting and
                confirming the requested service, should the final price be
                higher than expected then the following possible reasons may
be;
<ul>
                    <li>Traffic delays.</li>
                    <li>Drivers, Service provider’s device experienced
                        GPS/network issues.</li>
                    <li>Airport/parking fees/ toll gate fees or waiting period fees
                        were an additional to the price.</li>
                    <li>User has extended a few stopovers during the requested
                        service.</li>
                    <li>Driver, Service provider took different a route, the
                        machinery on site has worked over the estimated period of
                        time.</li>
                    <li>Final destination is further from the original destination
                        address, or scope of project has changed from the original
                        scope of work.</li>
                    <li>The duration set could’ve been overlapped therefore
                        resulting in additional fees.</li>
                    
                    </ul></p>
            <p>Users should also note that due to card authorisation, an extra
                charge may reflect to have been charged against the users
                account, but note that this is not an actual charge.
                </p>
            <p>Should users or service providers encounter any problems
                please contact Apivoka support chat through email.</p>
            
</div>
        </div>
    );
}