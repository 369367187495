import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick1 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Services weren’t rendered</h6>
            <p>Should consumers receive a receipt or a notification from the
                application but the services weren’t rendered or completely
                rendered on the platform. It may be that the requested service
                provider accidently started the service instead of cancelling the
                request.</p>
            <p>Should consumers believe that the service provider may have
                rendered the service to wrong consumer or location, please
                ensure to contact the Apivoka support centre for assistance</p>

            <p>Should consumers receive a charge notification about a
                payment that they don’t recognize, please directly contact
                Apivoka support chat through email.</p>

            </div>
        </div>
    );
}