import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay4 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Unauthorised charges</h6>
            <p>Users may incur additional fees on their Apivoka receipts.
                Please take note that these additional fees may be added due
                to requests are taken through major city gateways such as
                national tunnels or bridges.</p>
            <p>Should a user receive an unknown charge from Apivoka in
                their card or bank statement, users are advised to make sure to
                check for the following possibilities;<ul>
                    <li>Family members/friends using your card.</li>
                    <li>A pre-authorised or pending charge.</li>
                    <li>A request cancellation fee.</li>
                    <li>Old outstanding charges, due to banking or technical
                        issues that delayed payments.</li></ul></p>
            <p>Should users or service providers encounter any problems
                please do contact Apivoka support chat through email.</p>
            <p>Please not users are advised to get a recent bank statement
                with the actual charges being visible together with the user’s
                information register with Apivoka</p>

        </div></div>
    );
}