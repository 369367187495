import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Profile/prof1.css"


export const Prof5 = () => {
    return (
        <div className="container-fluid pro-cover">
            <SiList/>
            <div className="pro1-content">
            <h6>Sign up for newsletter</h6>
            <p>Apivoka newsletters update users with the latest
                information about pricing, latest services/products news and
                promotional offers</p>
            
            <p>Users who wish unsubscribe or receive our newsletters, should
                follow the following;<ul>
                    <li>Navigate to the main menu tab ( top left corner)</li>
                    <li>Open users profile tab</li>
                    <li>Select communication preferences and tap the button
                        close to ‘I want to receive Apivoka news and promotion
                        letters’</li ></ul></p>
            <p>SShould users experience problems while using an unsubscribe
                or subscribe from newsletters, you can simply directly contact
                Apivoka support chat through email.</p>

</div>
        </div>
    );
}