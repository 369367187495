import React from "react"
import { Link } from "react-router-dom"
import "../../assets/css/sectionWhyWeStandOut.css"

export const WhyStandOut = () => {



    return (
        <div className="container why">
            <div className="stand">
                <h1 ><strong>WHY WE STAND OUT</strong></h1>
                <h4 >Ease your stress and choose Apivoka .
</h4>
                <p>We understand the cumbersome procedures project managers, fleet managers, site managers and the ordinary individual persons has to experience just to have access to hire any sort of heavy equipment or logistic vehicles.
                         </p>
                <p>Our vast data base of quality, verified and outstanding service providers seeks nothing but to deliver top satisfying services in any requested field.
                   </p>
                <div class="whyButton">
                    <Link to="/Benefit"> <button type="button" class="btn btn-warning">Explore More</button></Link>

                </div>
            </div>      
             </div>
    )

}