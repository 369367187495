import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay13 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Using promotional code</h6>
            <p>Promotion codes are granted from time to time depending on
                the supply and demand of the particular service however first
                time users receive a welcome promotional discount on rates
                this applies to referral promotional codes which applicable to
                be used once off.</p>
            <p>To make use of the promotional code follow this guide;
                <ul>
                    <li>Navigate to the main menu (top left corner).</li>
                    <li>Select payments.</li>
                    <li>Select your preference of payment card.</li>
                    <li>Navigate to promotions (enter the promotion code).</li>
                    <li>Enter the promotional code and tap apply.</li>
                    <li>Navigate to promotions (enter the promotion code).</li>




                </ul>
            </p>
            <p>To view all the active promotional codes available to the
                user, you’re advised to visit the payments section in the main
                menu tab, this can assist get discounted rates before
                requesting the service in need of.</p>
                <p>
                Should users or service providers encounter any problems
                please do contact Apivoka support chat through email.
                </p>




</div>
        </div>
    );
}