import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick8 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Having technical issue</h6>

            <p>Should users encounter any technical issues meanwhile using
                the application, please seek for viable solutions provided below;
                <ul>
                    <li>Navigate to Google Play on an Android device or App
                        Store on an iOS device to whether the current application
                        needs to be updated.</li>
                    <li>Also users should consider updating the software system
                        of the operating phone.</li>
                    <li>If it the problem resumes restart the device.</li>
                    
                </ul></p>

            <p>Should none of the suggested solution not be of assistance to
                the users the can simply contact Apivoka support chat through
                email to get quick efficient assistance.</p>

</div>
        </div>
    );
}