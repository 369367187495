import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay7 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Price calculation in regards to promotion code</h6>
            <p>
                Apivoka promotional code enables users to receive
                discounted rates.
                  </p>
            <p> However should the price be higher than the value of the actual
                promotional code, the application will reflect the outstanding
                balance that need to be settled in payment.
        </p>
            <p> This process of payment can be done through the use of a card
                transaction or cash payment.
                  </p >
              <p>  Please note that should the requested service rates be less
                than the promotional code, be advice that the remaining will not
                be used for future reference but forfeited.  </p >
            <p>  Should users or service providers encounter any problems
                please do contact Apivoka support chat through email.
            </p>

            

</div>
        </div>
    );
}