import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay12 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Unsuccessful card payment</h6>
            <p>As a Apivoka user, you will receive an email about an
                unsuccessful card payment after the services has been
                rendered, however it may be because of several reasons.
                Apivoka will block your user account until the outstanding
                amount us settled.</p>
            <p>Common problems that may be the cause;
                <ul>
                    <li>The card has insufficient funds.</li>
                    <li>The card has expired.</li>
                    <li>E-commerce transactions may be disabled.</li>
                    <li>Have reached the daily limit.</li>
                    



                </ul>
            </p>
            <p>To resolve any card issues we recommend that users check
                with their banking institution and inform them of the matter,
                should the matter be fault of Apivoka then the user may
                contact Apivoka support chat through email.</p>




</div>
        </div>
    );
}