import React from "react";
import "../../assets/css/facilitator.css";

const Facilitator = () => {
  return (
    <div class="facilitator-cover">
      <div class="facilitator-content">
        {" "}
        <h3>
          <strong>FACILITATION AGREEMENT </strong>
        </h3>
        <h4>1. DEFINITIONS AND INTERPRETATIONS</h4>
        <ul>
          <span>These terms have the following meanings assigned to them:</span>
          <li>
            {" "}
            <strong>1.1</strong> When words and figures conflict, the words must
            prevail.
          </li>
          <li>
            {" "}
            1.2 The words “shall”, “must” and “will” in this agreement are
            mandatory obligations.
          </li>
          <li> 1.3 The singular includes the plural and vice versa.</li>
          <li>
            1.4 The headings of the clauses in this agreement are for
            convenience and reference only; and shall not be used in the
            interpretation, modification or amplification of the terms of this
            agreement.
          </li>
          <li> 1.5 The one gender includes the other.</li>
          <li>
            {" "}
            1.6 Apivoka (Pty) Ltd will be referred to as “The Facilitator”.
          </li>
        </ul>
        <h4> 2. AIM</h4>
        <span>
          The Facilitator commences the facilitation of Project to subcontract
          under principal contractor of which the aim is to ensure the parties
          share and understand a common objective and look to achieve ultimate
          performance and satisfaction in services provided. In so doing, the
          facilitator remains neutral and will use its tools to assist parties
          in achieving a consensus on any disagreements that pre-exist or emerge
          in the duration of the project so there may be a strong basis for
          future action and harmony in working relations.
        </span>
        <h4>3. FACILITATION AGREEMENT</h4>
        <span>
          The agreement has been prepared and is being signed by Apivoka and
          principal contractor.
        </span>
        <span>
          {" "}
          The agreement states that, principal contractor has agreed for
          Apivoka to facilitate for them on their behalf, through the service
          providers that Apivoka has listed on its database.
        </span>
        <span>
          {" "}
          The service provider will provide the services requested by the
          principal contractor through the facilitation of Apivoka , the
          commission charged for the facilitation will be paid by the principal
          contractor, depending; long term contacts payments are made either
          weekly, fortnight or monthly this will depend of the payment structure
          the principal contractor has received as per contract specifications
          in regards to one time request, payments will be made daily.
        </span>
        <span>
          <strong> Description of Project:</strong> will provide requested
          equipment needed to execute the project by subcontracting under
          principal contractor of which monthly or per one time request will be
          entitled to an agreed facilitation fee thereof for an agreed period.
        </span>
        <span>
          <strong>Number of Equipment: </strong>04 (addendum will be drafted in
          the event that more equipment are added)
        </span>
        <h4>4. PAYMENT DETAILS</h4>
        <span>
          Facilitation Fee due to Apivoka will deduct its commission upon the
          payment being made by the client.
        </span>
        <h4> 5. DETAILS OF THE FACILITATOR:</h4>
        <span>
          <strong>Name of the company:</strong> Apivoka (PTY) Ltd
        </span>
        <span>
          <strong>Company registration:</strong> 2019/091222/07
        </span>
      { /* <span>
          <strong>Name of Representative:</strong> Roro Linda Malabe
        </span>
        <span>
          <strong>Designation:</strong> Director
        </span>
        <span>
          <strong>Contact No.:</strong> 082 782 8228
        </span>
        */}
        <span>
          {" "}
          <strong>Address:</strong> 373 Luttig Street. Pretoria West, Pretoria.
          0183
        </span>
        <h4> 6. DUTIES OF THE PARTIES</h4>
        <span>
          The parties agree that business will be conducted with transparency
          and honesty and neither party will seek to circumvent, exclude and/or
          withhold information that could be pertinent and important and/or
          payment due to either party for works conducted under this agreement.
        </span>
        <h4>7. TERMINATION AND BREACH </h4>
        <ul>
          {" "}
          <li>
            7.1. If the equipment(s) is destroyed or damaged to the extent that
            it cannot be used, this agreement will terminate unless the Parties
            agree in writing otherwise.
          </li>
          <li>
            {" "}
            7.2. This agreement shall not terminate by the death of either
            Party: the executor of the deceased estate shall elect to terminate
            or uphold this agreement depending on the circumstances.
          </li>
        </ul>
        <h4> 8. GENERAL</h4>
        <ul>
          {" "}
          <li>
            8.1. This agreement constitutes the whole agreement between the
            Parties.
          </li>
          <li>
            {" "}
            8.2. This agreement may only be amended if the Parties agree to the
            amendment in writing, and sign the written document, in ink by hand
            – which must be attached to this agreement as an Annexure.
          </li>{" "}
          <li>
            {" "}
            8.3. No Party shall be bound by any express or implied term,
            representation, warranty, promise, or the like not recorded in this
            agreement. .
          </li>
          <li>
            8.4. Non-legal documents, notices or other communication between the
            Parties may be by electronic communication; where a “read receipt”
            must be attached to each and every electronic communication sent so
            it can be determined that the electronic communication was received
            and read by the other Party.
          </li>{" "}
          <li>
            8.5. The agreement shall come into effect upon successful review and
            signing of Memorandum of Agreement between principal contractor and
            service provider.
          </li>
        </ul>
        <h4>9. SPECIFICATIONS</h4>
        <span>
          {" "}
          All specifications of the project are as stipulated in the principal
          contractor’s contract. This will ensure all parties are well aware of
          their duties, rights and limitations with possible outcomes should the
          specifications be done as mentioned in the contract.
        </span>
      </div>
    </div>
  );
};

export default React.memo(Facilitator);
