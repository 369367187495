import React from "react"
import { useHistory } from "react-router-dom"
import "../../assets/css/sectionWhatWeDo.css"

export const WhatWeDo = () => {

const history =useHistory();
const handleClick=()=>{
    history.push('/heavy-equipemt-tippers-for-hire')
}

    return (
        <div className="container what">
            <div className="wedo">
                <h1 ><strong>WHAT WE DO</strong></h1>
            <h4 style={{color:"white"}}>Connecting People.Improving Lives</h4>
                <p>We are a technology solution oriented organization. Our automation application caters to have a direct  positive impact on mass production with minimum costs.  Heavy equipment and logistic vehicles are easily accessible for hire.</p>
                <p>Through our database both logistic and equipment owning companies or individuals have the ability to list their equipment or logistic vehicles with us for free,giving  access  to a secured, reliable free market to operate from.
                </p>
                <div class="text-center  whatButton "
                      >
                    <button type="button" class="btn bounce-out-on-hover  " onClick={handleClick}>Explore More</button>

            </div>
            </div>
        </div>
    )

}