import React from "react"
import {  Link } from 'react-router-dom';
import $ from 'jquery';
import WhoAreWe from "components/LandingPage/sectionWhoWeAre";
import { WhatWeDo } from "components/LandingPage/sectionWhatWeDo";
import { WhyStandOut } from "components/LandingPage/sectionWhyStandOut";

import "../../assets/css/home1.css"
import AppPicture1 from "../../assets/img/construction-equipment-hire.jpg"
import AppPicture from "../../assets/img/logistics.jpg"
import AppPicture2 from "../../assets/img/farming-equipment-hire.jpg"
import AppPicture3 from "../../assets/img/mining-equipment-hire.jpg"
import AppPicture4 from "../../assets/img/app-req.jpg"



function Home1() {

    $(function () {
        let percent = 0;
        let bar = $('.transition-timer-carousel-progress-bar');
        let crsl = $('#carouselExampleIndicators');

        function progressBarCarousel() {
            bar.css({ width: percent + '%' });
            percent = percent + 0.5;
            if (percent >= 100) {
                percent = 0;
                crsl.carousel('next');
            }
        }

        crsl.carousel({
            pause: "hover"
        });

        let barInterval = setInterval(progressBarCarousel, 25);

        crsl.on("mouseenter", function () {
            clearInterval(barInterval);
        });
        crsl.on("mouseleave", function () {
            barInterval = setInterval(progressBarCarousel, 25);
        });
    })




    return (
        <div>
            <hr class="transition-timer-carousel-progress-bar" />

            <div id="carouselExampleIndicators" class="home1 carousel slide" data-ride="carousel" data-interval="false">

                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                </ol>

                <div class="carousel-inner">
                    <div class="carousel-item active img-gradient">
                        <img rel="preload" class="d-block w-100" src={AppPicture4} quality={100} alt="picktech-app" as="image" />
                        <div class="carousel-caption  d-md-block content">

                            <div class="carousel-caption-inner ">
                                <h6 className="animate__animated  animate__fadeInUp" style={{ animationDelay: "0.5s" }}><strong>Modernizing the core operations and access of heavy equipment and logistic vehicles.</strong></h6>
                            <p className="animate__animated  animate__fadeInUp" style={{ animationDelay: "1s" }}>Welcome to the 4th industrial revolution have easy access to hire trucks, bakkies, vans or
heavy equipment within the construction, mining and agriculture industries</p>
                            <Link to="/AboutUs">  <button type="button" className="animate__animated  animate__fadeInUp slideButton" style={{ animationDelay: "2s" }} >Explore More</button></Link>
                        </div>
                        
                        </div>
                    </div>
                    <div class="carousel-item img-gradient">
                        <img rel="preload" class="d-block w-100" src={AppPicture} quality={100} alt="logistics" as="image" />
                        <div class="carousel-caption  d-md-block content">
                            <h6 className="animate__animated  animate__fadeInUp" style={{ animationDelay: "0.5s" }}><strong>Modernizing the core operations and access of heavy equipment and logistic vehicles.</strong></h6>
                            <p className="animate__animated  animate__fadeInUp" style={{ animationDelay: "1s" }}>Welcome to the 4th industrial revolution have easy access to hire trucks, bakkies, vans or
heavy equipment within the construction, mining and agriculture industries.</p>
                            <Link to="/AboutUs">  <button type="button" className="animate__animated  animate__fadeInUp slideButton" style={{ animationDelay: "2s" }} >Explore More</button></Link>

                        </div>

                    </div>
                    <div class="carousel-item img-gradient">
                        <img rel="preload" class="d-block w-100" src={AppPicture2} quality={100} alt="farming" as="image" />
                        <div class="carousel-caption  d-md-block content">
                            <h6 className="animate__animated  animate__fadeInUp" style={{ animationDelay: "0.5s" }}><strong>Modernizing the core operations and access of heavy equipment and logistic vehicles.</strong></h6>
                            <p className="animate__animated  animate__fadeInUp" style={{ animationDelay: "1s" }}>Welcome to the 4th industrial revolution have easy access to hire trucks, bakkies, vans or
heavy equipment within the construction, mining and agriculture industries.</p>
                            <Link to="/AboutUs">  <button type="button" className="animate__animated  animate__fadeInUp slideButton" style={{ animationDelay: "2s" }} >Explore More</button></Link>

                        </div>
                    </div>
                    <div class="carousel-item img-gradient">
                        <img rel="preload" class="d-block w-100" src={AppPicture3} quality={100} alt="mining" as="image"/>
                        <div class="carousel-caption  d-md-block content">
                            <h6 className="animate__animated  animate__fadeInUp" style={{ animationDelay: "0.5s" }}>Modernizing the core operations and access of heavy equipment and logistic vehicles.</h6>
                            <p className="animate__animated  animate__fadeInUp" style={{ animationDelay: "1s" }}>Welcome to the 4th industrial revolution have easy access to hire trucks, bakkies, vans or
heavy equipment within the construction, mining and agriculture industries.</p>
                            <Link to="/AboutUs">  <button type="button" className="animate__animated  animate__fadeInUp slideButton" style={{ animationDelay: "2s" }} >Explore More</button></Link>

                        </div>
                    </div>
                    <div class="carousel-item img-gradient">
                        <img rel="preload" class="d-block w-100" src={AppPicture1} alt="construction" as="image"/>
                        <div class="carousel-caption  d-md-block content">
                            <h6 className="animate__animated  animate__fadeInUp" style={{ animationDelay: "0.5s" }}>Modernizing the core operations and access of heavy equipment and logistic vehicles.</h6>
                            <p className="animate__animated  animate__fadeInUp" style={{ animationDelay: "1s" }}>Welcome to the 4th industrial revolution have easy access to hire trucks, bakkies, vans or
heavy equipment within the construction, mining and agriculture industries.</p>
                            <Link to="/AboutUs">  <button type="button" className="animate__animated  animate__fadeInUp slideButton" style={{ animationDelay: "1.5s" }}>Explore More</button></Link>
                        </div>
                    </div>
                    
                    <a class="animate__animated  animate__fadeInUp  scroll-down" style={{
                        animationDelay: "2s"}} href="#whoScroll">
                        
                        <div class="box">
                            
                            <span></span>
                            <span></span>
                            <span></span>
                            
                            
                        </div>
                        
                    </a>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">

                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">

                    <span class="sr-only">Next</span>
                </a>
               
            </div>
            <div id="whoScroll"/>
            <div >
            <WhoAreWe />
            </div>

            <div style={{ "backgroundColor": "black", "background": "cover" }}>
                <WhatWeDo style={{ width: "100%", "background-color": "black" }} />
            </div>

            <WhyStandOut />
            
            
            
        </div>
    )
}


export default React.memo(Home1);