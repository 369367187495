import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay14 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Unable to add supported card</h6>
            <p>Promotion codes are granted from time to time depending on
                the supply and demand of the particular service however first
                time users receive a welcome promotional discount on rates
                this applies to referral promotional codes which applicable to
                be used once off.</p>
            <p>Should users encounter problems while trying to add the
                supported card to the platform, here a possible solution to
                assist;
                <ul>
                    <li>Ensure that card details are correct as per visible on the
                        banking card.</li>
                    <li>Ensure there are sufficient funds</li>
                    <li>Verify with the banking intuition for E-commence
                        transactions</li>
                    <li>Ensure that it’s a master card or visa card.</li>
                   



                </ul>
            </p>
            <p>
                Should users or service providers encounter any problems
                please do contact Apivoka support chat through email.
            </p>




</div>
        </div>
    );
}