import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick12 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Reporting accidents or irregular behaviour</h6>
            <p>In cases whereby the service provider has acted in an
                appropriately manner of the condition of the machinery or truck
                is in poor condition, users should leave a rating on the
                application once the service requested has been rendered and
                please provide detailed feedback.</p>
            <p>This will enable us to do an investigation into the matter
                reported and take an affirmative action where required.</p>
            <p>Should an accident or any form of dangerous situation take
                place users are advised to please contact Apivoka support
                chat through email reporting the situation at hand and the
                admin team will further assist and help contact local authorities.</p>

</div>
        </div>
    );
}