import React from "react"
import "../../assets/css/comingSoon.css"
 const    Coming=()=>{


return(
    <div class="wrapper">
     <div className="inner">   <h1>coming soon</h1>
        <p>Follow us on our social media platforms for all updates !</p>
        <div class="icons text-center">
                <a href="https://www.facebook.com/picktechpvt"><i class="fa fa-facebook-square fa-lg"></i></a>
                <a href="https://www.linkedin.com/company/picktechpvtltd"><i class="fa fa-linkedin-square fa-lg"></i></a>
                <a href="https://twitter.com/Apivoka4"><i class="fa fa-twitter-square fa-lg"></i></a>
                <a href="https://www.instagram.com/picktech_rsa/"><i class="fab fa-instagram fa-lg"></i></a>
          
        </div>
        </div></div>
)
}

export default Coming ;