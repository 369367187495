import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick6 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Changing of location</h6>
            <p>When users wish to change the location provided, and then
                users should do so before confirming the requested service. If
                the requested service is already confirmed then users can
                cancel the request or use the change location option which will
                result to extra charges.</p>
            <p>Should users encounter a problem trying to change the
                location, please directly contact Apivoka support chat through
                email for assistance.</p>

</div>
        </div>
    );
}