import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Signing/sign1.css"

export const Sign2 = () => {
    return (
        <div className="container-fluid s1-cover">
            <SiList/>
            <div className="s1-content">
            <h6>How do I create a Apivoka account?</h6>
            <p>Creating an Apivoka account is made simple, this process will require a
                valid ID or passport, email address, cell phone number and a valid
                registered business.</p>
            <p>Creating a client account can be done through Apivoka&#39;s official website
                or through the &#39;Register&#39; button on the Mobile Application, the link will
                redirect the client to a site where they&#39;ll be required to fill in a form with their relevant details and required documents, after the
                Apivoka&#39;s administration has verified the application the client will receive
                notification through mail that their account is ready for operating.</p>
           </div>
        </div>
    );
}