import { SiList } from "components/Buttons/SiList";
import React from "react";
import {Link} from "react-router-dom"
import "../../assets/css/mining.css";


function Mining() {
  return <div className="miningCover  ">
    
    <div className="miningContent">
      <div><h1><strong><span>Minin</span>g Equipment</strong></h1>
    <p>It is evident that the mining industry is a key industry that contributes the country’s economy, not only that but it has a direct impact to the economy’s performance so it is important always implement innovative technologies to cater for the demand in place.
    </p><p>Both individuals and companies can easily list any mining equipment in their possession into our data base for free.
    Through our digital platform users can request to hire any sort of required equipment on any mining site without any hassle. This will ease the procedures of request any equipment on site to execute any mining activity.
        </p>
       {/* <div class="col-md-12 text-center miningButton">
          <button type="button" className="btn1 " >List Equipment</button>
          <Link to="/Coming">  <button type="button" className="btn1" >Register to Apivoka</button> </Link>
        </div>*/}
        </div>
        <SiList/>
    </div>
    
  </div>;
}

export default React.memo(Mining);
