import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick9 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Rating a ride</h6>
            <p>At the end of each service rendered, users have the option to
                rate the service received from the service provider, however it
                being optional we encourage users to rate the service received.</p>
            <p>This is an automated process that pops up at the end of the
                service.</p>
            <p>However should users experience glitches or any sort of
                malfunction while trying to rate the service; please contact
                Apivoka support chat through email to get assistance from the
                admin team.</p>
</div>

        </div>
    );
}