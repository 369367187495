
import React from "react";


import "../../assets/css/aboutus.css"

import Section1 from "./section1";



import About from "./About";
import { SiList } from "components/Buttons/SiList";


 function AboutUs() {
    return (
        <div className="about1" >
            
            <header>
                <h1><strong>ABOUT US</strong></h1>
            </header>
            
            
            <About />
               <Section1/>

               
                
              
             
            </div>
            

    

       );
}

export default React.memo(AboutUs);