import React from "react";
import "../../assets/css/farming.css";
import {Link } from "react-router-dom"


function Agriculture() {
  return <div className="farmingCover">
    
    <div className=" float-xl-right  farmingContent">

      <div className="agric-cont"><h1><strong><span>Agricultur</span>e Equipment</strong></h1>
        <p>
          Agriculture is a major industry, amongst the existing industries in South Africa and has proven to be a very important contributor the GDP. It is evident that the industry could benefit from technology being a catalyst to attain mass productivity and be cost effective.
           </p><p>Through our platform, individuals and companies in possession of Agricultural equipment can be list it for free, on our data base.
        Our digital platform allows users to be able to request  to hire any desired agricultural equipment listed on our data base to execute any project within the space of Agriculture. 
          <div class="col-md-12 text-center farmingButton">
            <button type="button" className="btn " >List Equipment</button>
            <Link to="/Coming">  <button type="button" className="btn" >Register to Apivoka </button> </Link>
          </div>
            </p></div>
      
      <div />
    </div>
  </div>;
}

export default React.memo(Agriculture);
