import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay6 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Issues with referral promotion codes</h6>
            <p>Should it happen that users encounter problems when using a
                promotional code, please ensure the following;
            <ul>
                    <li>TThe promotional code hasn’t expired and meets all
                        requirements.</li>
                    <li>The promotional code has been rightfully applied.</li>
                    <li>Ensure that it’s the latest version of the application.</li>
                </ul></p>

            <p>Should the problem persist users or service providers
                encounter any problems please do contact Apivoka support
                chat through email.</p>
            

        </div>
        </div>
    );
}