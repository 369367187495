import { SiList } from "components/Buttons/SiList";
import React from "react";
import  "../../assets/css/frequent.css"
import Suppp from "../../assets/img/supporting.jpg"

const Frequent=()=>{
    return(
<div className="container-fluid freq-cover">
            <div className="banner-breadcum section">
                <div className="row">
                    <div class="breadcrumb-image" style={{ background: `url(${Suppp})`, color: 'black' }}>
                        <h1 className="text-center ">Frequently asked questions</h1>
                        
                    </div>
                </div>
                
            </div>
<SiList/>
    <div className="serviceProvider mt-7 mb-4">
        <h2>Service Providers</h2>
        
            
                <h6>Q1. How do I register and list my equipment or logistic vehicle?</h6>
  <p> -Simply go the Apivoka&#39;s official website and click on the &quot;list&quot; tab and you
will be redirected to the site, whereby you&#39;ll fill<br/> in the required information
and relevant documentation for registration. This process is free.</p>
                <h6>Q2. Will I be able to lease my equipment or logistic vehicle outside the
                    Apivoka ecosystem?</h6>
                <p>- Apivoka&#39;s system is a designed to flexible and tailored for every
                    individual, this means that you are given an opportunity to outsource your
                    equipment or logistic vehicle outside Apivoka&#39;s ecosystem without any
                    complications.</p>
                <h6>Q3. Will I be able to track, control movements of my equipment of logistic
                    vehicle?</h6>
                <p> - Apivoka&#39;s system has a driver&#39;s application that helps equipment and
                    logistic vehicle owner to keep track of their assets movements, real time
                    tracking and a given ETA (Estimated Time of Arrival) this will give you the
                    opportunity to have more insight about the operations and location of your
                    asset.</p>
                <h6> Q4. How will I receive payments for services rendered?</h6>
                <p> - Payments will to service providers will be paid directly from Apivoka, we
                    will facilitate the payments from the client to the service provider to ensure
                    that both parties are happy and meet their obligations.</p>
                <h6> Q5. Who can I contact if payments are not made?</h6>
                <p> - In case as a service provider you haven&#39;t received your payment for the
                    service you&#39;ve rendered to a client through the platform, you can simply
                    get in touch with the Apivoka support team; contact details are available
                    on the official website of Apivoka and a agent will be at your assistance.</p>
                  
                    
    

    </div>
            <div className="client mt-7 mb-4">
                <h2>Clients</h2>


                <h6>Q1. What is Apivoka ?</h6>
                <p> - Apivoka is Mobile Application designed to automate the old traditional
                    ways of operating within the construction, mining, agriculture and logistic
                    industry. At the tip of your fingers you have access to limitless earth heavy
                    equipment, logic vehicles and agriculture equipment.</p>
                <h6>Q2. Is Apivoka reliable?</h6>
                <p> - All service providers listed with Apivoka go through a vetting process to
                    ensure that they&#39;re in position to render the services required by the clients
                    requesting, provide a safe and transparent working environment.</p>
                <h6>Q3. Who do I contact if I experience a problem using the platform?</h6>
                <p> - If case of any incidents or enquiries you can simply contact our support
                    team through the website or Mobile Application through the support
                    contact details provided. A Apivoka agent will respond within a short
                    period of time.</p>
                <h6> Q4. Why choose Apivoka?</h6>
                <p> - The services offered by Apivoka are designed to be tailor made for every
                    individuals needs, ease your stress, be time and cost efficient and take
                    advantage of Apivoka&#39;s vast ecosystem of verified and authenticated
                    service providers.</p>
                <h6> Q5. How can I register with Apivoka?</h6>
                <p> - Registering with Apivoka is designed to be simple, you can easily go to
                    the Apivoka official website and click on the &#39;register&#39; tab and you&#39;ll be
                    redirected to a site where you&#39;ll be required to fill in necessary information
                    and submit relevant documentation and the administration team will
                    process the registration.</p>




            </div>


</div>
    );
}

export default Frequent;