import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick10 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Estimated time off arrival</h6>
            <p>Once the user confirms the requested service then the
                application will give a real live time estimation of the service
                provider; the exact location and time it would take for the
                service provider to reach the destination of the requested
                service.</p>
            <p>Please note that the estimated time is not the actual time,
                changes made occur due to different contributors such as
                heavy traffic jams, or accidents on the service provider’s route.</p>
            <p>The Estimated Time off Arrival (ETA):
                <ul>
                    <li>The distance calculated between the service provider and
                        the location of the consumer requesting the services.</li>
                    
                    <li>Any roadblocks or any current heavy traffic.</li>

                </ul></p>

            <p>Should users have any problems in regards to the ETA please
                contact Apivoka support chat through email for assistance and
                the IT department will immediately attend to the matter.</p>

</div>
        </div>
    );
}