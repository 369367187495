import React from "react"
import { Link } from "react-router-dom";

import Arrow from "../../../assets/img/right-arrow.png"
import "../../../assets/css/Payment/payment.css"
import { SiList } from "components/Buttons/SiList";

export const Payment = () => {
    return (
        <div className="container-fluid pay-cover">
            <SiList/>

            <div className="pay-content container"> <h2>Payment and pricing</h2>
            <p>Price higher than expected<Link to="/higher-prices-than-expected"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Additional fees<Link to="/additional-fees"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>

               
                <p>Unauthorised charges<Link to="/unauthorised-charges"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Double charged for services<Link to="/doubled-charged"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Issues with referral promotion codes<Link to="/Issues-referral-promotion-codes"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
            <p>Price calculation in regards to promotion code<Link to="/price-calculation"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Issues with a promotional code<Link to="/issues-promotional-code"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Waiting period fee<Link to="/waiting-period-fee"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Cancelling requested service<Link to="/cancelling-request"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Introduction to Upfront Pricing<Link to="/upfront-pricing"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Unsuccessful card payment<Link to="/unsuccesful-card-payment"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Using promotional code<Link to="/using-promotional-code"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Unable to add supported card<Link to="/unable-supported-card"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Price estimation<Link to="/price-estimation"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Truck and Machinery calculators<Link to="/truck-machinery-calculator"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Removing a card.<Link to="/removing-card"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>

            </div>
        </div>
    );
}