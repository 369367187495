import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Profile/prof1.css"


 const Prof8 = () => {
    return (
        <div className="container-fluid pro-cover">
            <SiList />
            <div className="pro1-content">
            <h6>Requesting user data</h6>
            <p>Apivoka application will process personal information of users
                for them to be able to use the applications services. When installing and signing up in the Apivoka application, users
                agree with out General Terms and Conditions and users give
                the consent for collecting and processing your data as specified
                in Art.6.1(b) of General Data Protection Regulation.</p>
            <p>To get more information about the processing of your data,
                please make reference to Apivoka 's Privacy Policy for
                Passengers.</p>

            <p>User’s personal information will be stored provided the user has
                an active account. Should the users account be deactivated the
                personal information of the specific users will be removed from
                our database exceptional cases are when the personal
                information is required for;<ul>
                    <li>Accounting purposes</li>
                    <li>Dispute resolution</li>
                    <li>Fraud prevention purposes</li ></ul></p>
            <p>Should users experience problems while using an unsubscribe
                or subscribe from newsletters, you can simply directly contact
                Apivoka support chat through email.</p>
</div>

        </div>
    );
}

export default Prof8;