import React from "react"
import "../../assets/css/privacy.css"

 const Privacy = () => {
    return (
        <div class="privacy-cover">
            <div class="privacy-content">  <h3><strong>Terms and conditions </strong></h3>
            <span className="">Welcome to Apivoka (Pty) Ltd</span>
            <span>Thanks for using our products and services (“Services”). The services are provided by Apivoka Pty
                Ltd, located at 373 Luttig Street, Pretoria, Gauteng, South Africa.</span>
            <span>By using our services, you are agreeing to these terms. Please read these Terms and Conditions
                (‘’Terms’, “Terms and Conditions”) carefully before using the https://www.Apivoka .com and the mobile
                application (the “Service”) operated by Apivoka (Pty) Ltd (“us, “we”, or “our”).</span>
            <span>Our services are very diverse, so sometimes additional terms or products requirements (including age
                requirements) may apply. Additional terms will be available with the relevant Services, and those
                additional terms become part of your agreement with us if you use those Services.</span>


                <h4> Terminology</h4>
                <span> The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer
                    Notice, and any or all Agreements: “’Client”, “You” and “Your” refer to you, the person accessing this
                    website and accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We” and
                    “Us” refer to our Company. “Party”, “Parties” or “Us” refers to both the Customer and ourselves, or
                    either the Customer or ourselves. All terms refer to the offer, acceptance and consideration of payment
                    necessary to undertake the process of our assistance to the Client in the most appropriate manner,
                    whether through formal meetings of a fixed duration, or by any other means, with the express purpose
                    of meeting the Client’s needs in terms of providing the Company’s declared services / products, in
                    accordance with and subject to applicable US laws. Any use of the above terminology or other words in
                    the singular, plural, capital letters and/or plural, and/or these terms, is considered interchangeable and
                    therefore a reference to them.
                    Using our services</span>
                <h4>Using our services</h4>
                <span>You must follow any policies made available to you within the Services. Don’t misuse our Services. For
                    example, don’t interfere with our Services or try to access them using a method other than the interface
                    and the instructions that we provide. You may use our services only as permitted by law, including
                    applicable export and re-export control laws and regulations. We may suspend or stop providing our
                    Services to you if you do not comply with our terms or policies or if we are investigating suspected
                    misconduct.</span>

                <span>Using our Services does not give you ownership of any intellectual property rights in our Services or the
                    content you access. You may not use content from our Services unless you obtain permission from its
                    owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or
                    logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with
                    our Services.</span>
                <span>In connection with your use of the Services, we may send you service announcements, administrative
                    messages, and other information. You may opt out of some of those communications.</span>
                <span>  Some of our Services are available on mobile devices. Do not use such Services in a way that distracts
                    you and prevents you from obeying traffic and safety laws.</span>

                <h4> Privacy Statement</h4>
                <span>We are committed to safeguarding and protecting your privacy. Apivoka (Pty) Ltd’s privacy policies
                    explain how we treat your personal data and protect your privacy when you use our Services. By using
                    our Services, you agree that Apivoka Pty Ltd can use such data in accordance with our privacy
                    policies.</span>
                <span> Only authorized employees within the company who, in the course of their duties, can access and use
                    information collected from individual customers.</span>
                <span> We are constantly reviewing our systems and data to ensure the best possible service to our
                    customers. Government authorities have created specific offences for unauthorized actions against
                    computer systems and data. We will investigate computer systems and data. We will investigate such
                    actions with a view to bringing legal action and / or civil action for damages against those responsible.</span>
                <h4>   Software in our services</h4>
                <span> When a Service requires or includes downloadable software, this software may update automatically on
                    your device once a new version or feature is available. Some Services may let you adjust your
                    automatic update settings.</span>
                <span>  Apivoka Pty Ltd gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive
                    license to use the software provided to you by Apivoka (Pty) Ltd as part of the Services. This license is
                    for the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by
                    Apivoka Pty Ltd, in the manner permitted by these terms. You may not copy, modify, distribute, sell or
                    lease any part of our Services or included software, nor may you reverse engineer or attempt to extract

                    the source code of that software, unless laws prohibit those restrictions or you have our written
                    permission.</span>
                <h3> <strong>Disclaimer</strong></h3>
                <h4>  Exclusions and Limitations</h4>
                <span> The information contained on this application and website is provided on an “as is” basis. To the fullest
                extent permitted by law, this company:
              <ul>  <li> Excludes all representations and warranties with respect to this website and application, and its
                content or that are or may be provided by affiliates or any other third party, including with
                respect to any inaccuracy or omission in this website and application and/or Company’s
                        documentation; and</li>
                <li> Excludes any liability for damages arising out of or in connection with your use of this
                application and website, Apivoka and Apivoka 's service providers, will not be responsible for
                loss of profits, revenue, data, financial losses or indirect special, consequential exemplary,
                punitive damages or damage caused to your computer, computer software, systems or
                programs and data relating thereto or any other direct or indirect, consequential or incidental
                        damages.</li>
                    </ul>
                </span>

                <h4> Liability for our Services</h4>
                <span>To the extent permitted by law, the total liability of Apivoka and its service providers and consumers,
                    for any claims under these terms, including for any implied warranties, is limited to the amount you paid
                    us to use the Services.</span>
                <span> In all cases, Apivoka and service providers will not be liable for any loss or damage that is not
                    reasonably foreseeable.</span>
                <span> However, this company does not exclude the liability for death or personal injury caused by its
                    negligence. The above exclusions and limitations apply only to the extent permitted by law. We
                    recognize that in some countries, you might have legal rights as a customer. None of your legal rights
                    as a consumer are affected waived by contract.</span>

                <h4>Business uses of our services</h4>
                <span>If you are using our Services on behalf of a business, that business accepts these terms. It will hold
                    harmless and indemnify Apivoka and its affiliates, officers, agents and employees from any claim, suit
                    or action arising from or related to the use of the Services or violation of these terms, including any
                    liability or expense arising from claims, losses, damages, suits, judgements, litigation costs and
                    attorney’s fees.</span>
                <h4> Cancellation Policy</h4>
                <span>A minimum 24hrs* notice of cancellation is required. Such notice may be given, by email and will be
                    accepted subject to written confirmation. We reserve the right to charge a cancellation fee of % to cover
                    any administrative costs.</span>
                <h4>Modifying and terminating our services</h4>
                <span>We are constantly changing and improving our services. We may add or remove functionalities of
                    features, and we may suspend or terminate a service altogether.</span>
                <span> The customer and Apivoka have the right to terminate any Services Agreement for any reason
                    whatsoever, including the termination of services already in progress. Apivoka may also stop providing
                    Services to you or add or create new limits to our Services at any time.</span>
                <h4>  Refund Policy</h4>
                <span>No refund will be offered when a service is deemed to have commenced and is, for all intents and
                    purposes, in progress. Any amount paid to us that constitutes payment for the provision of unused
                    Services, will be refunded.</span>
                <h4> Log Files</h4>
                <span> We use IP addresses to analyse trends, administer the application, site, track user’s movement, and
                    gather broad demographic information for aggregate use IP address are linked to personally identifiable
                    information. Additionally, for systems administration, detecting usage patterns and troubleshooting
                    purposes, our web servers automatically log standard access information including browser type,
                    access time/ open mail, URL requested and referral URL. This information is not shared with third
                    parties and is used only within this Company on a need to know basis. Any individually identifiable
                    information related to this data will never be used in any way different to that stated above without your
                    explicit consent.</span>

                <h4> Cookies</h4>
                <span>Like most interactive applications and websites, this Company’s website uses cookies to enable us to
                    retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality
                    of this are and ease of use for those people visiting. Some of our affiliate partners may also use
                    cookies.</span>
              <h4> Links others website</h4>
                <span> Our Service may contain links to third-party web sites or services that are not owned or controlled by
                    Apivoka .</span>
                <span> Apivoka has no control over, and assumes no responsibility for, the consent, privacy policies or
                    practices of any third-party web sites or services. The opinions expressed or material appearing on
                    these websites is not necessarily shared or endorsed by us and should not be considered as the
                    publisher of such opinions or material. Please note that we are not responsible for the privacy practices
                    or content of these sites. We encourage our users to be aware when they leave our site and to read the
                    privacy statements of these sites.</span>
                <span> You should evaluate the security and reliability of any other site linked to or accessed through that site
                    for any loss or damage, in any manner whatsoever, regardless of the cause, resulting from your
                    disclosure of personal information to third parties.</span>
                <span>You further acknowledge and agree that Apivoka shall not be responsible or held liable, directly or
                    indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or
                    reliance on any such content, services available on or through any such web sites or services.
                    Links to the website and application</span>
                <span> You may not create a link to a page on this website without our prior written consent. If you think to any
                    page on this website, you do so at your own risk and the exclusions and limitations set out above apply
                to your use of this website.</span>
           <h4> Copyright Notice</h4>

        
                <span> Copyright and other relevant intellectual property rights exist on all text relating to the company’s
                    services and full content of this website. This company’s logo is registered trademark of this company in
                    the Republic of South Africa and other countries. The brand names and specific services of this
                    company featured on this web site are trademarked. </span>

                <h4>Force Majeure</h4>
                <span>Neither party shall be liable to the other for any failure to perform any obligation under any Agreement
                    which is due to an event beyond the control of such a party including but not limited to any Act Of God,
                    terrorism, war, Political insurgence, insurrection, riot, civil unrest, and act of civil or outside of our
                    control, which causes the termination of agreement or contract entered into, nor which could have been
                    reasonably foreseen. Any party affected by such event shall forthwith inform the other party of the same
                    and shall use all reasonable endeavours to comply with the terms and conditions of any agreement
                    contained herein.</span>
                <h4>Waiver</h4>
                <span>Failure of either party to insist upon strict performance of any provision of this or any agreement or here
                    the failure of either party to exercise any right or remedy to which it, he or they are entitled hereunder
                    shall not constitute a waiver thereof and shall not cause a diminution of the obligations under this or any
                    agreement. No waiver of any of the provisions of this or nay Agreement shall be effective unless it is
                    expressly stated to be such and signed by both parties.</span>
                <h4>About these terms</h4>
                <span>We reserve the right to modify the terms and conditions or add any terms that apply to a Service to, for example, reflect charges to the law or charges to our Services. You should look at the terms regularly.
                    If a revision is material, well try to provide at 10 days’ notice prior to any terms taking effect. What constitutes a material change will be determined at our sole discretion.</span>
                <span> We will post notice of modifications to these terms on this page. We will post notice of modified
                    additional terms in the applicable Service. Changes will not apply retroactively and will become effective
                    no sooner than 10 days after they are posted. However, changes addressing new functions for a
                    service or changes made legal immediately. If you do agree to the modified terms for a service, you
                    should discontinue your use of that service.</span>
                <span>  These terms control the relationship between Apivoka and you. They do not create any third-party
                    beneficiary rights.</span>
                <span>If you do not comply with these terms, and we don’t act right away, this doesn’t mean that we are giving
                    up any rights that we may have (such as taking action in future).</span>

                <span>If it turns out that a particular term is not enforceable, this will not affect any other terms. The counts in
                    some countries will not apply to * law to some types of disputes. If you reside in one of these countries,
                    then where * law is excluded from applying. Your country’s laws will apply to such disputes related to
                    these terms.</span>
                <h4>  Contact Us</h4>
                <span>
                    If you have any questions about these terms, please contact us at info@Apivoka .com.</span>
            </div>

        </div >);
        
   
}

export default React.memo(Privacy);

