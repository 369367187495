import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick14 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Cancelling services requested</h6>
            <p>Cancellation of services requested is permitted before
                confirming the request. However once the service is requested
                and users wish to cancel the service they may continue to
                cancel it however the user will be charged a cancellation fee
                which is 20% to equivalent of the initial cost of the overall
                service requested.</p>
            <p>Should users encounter problems trying to cancel a requested
                service please contact Apivoka support chat through email for
                assistance.</p>


        </div>
        </div>
    );
}