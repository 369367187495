import React from "react"
import "../../assets/css/Buttons/silist.css"
import { Link } from "react-router-dom";
export const SiList=()=>{
    return(
        <div className="call-to-act row section" style={{ backgroundColor: '#FFC000' }}>
            <div class="col-md-12 text-center sign-button">
                <button type="button" className="btn " >List Equipment</button>
                <Link to="/Coming"> <button type="button" className="btn" >Register to Apivoka </button> </Link>
            </div>
        </div>
    );
}