import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Payment/pay1.css"


export const Pay9 = () => {
    return (
        <div className="container-fluid p1-cover">
            <SiList/>
            <div className="p1-content">
            <h6>Waiting period fee</h6>
            <p>
                The waiting period fee is charged per minute which starts once
                the service provider arrive the destined location and continues
                until the service provider starts the requested service. The fee
                is put in place to compensate the service provider for any
                delays caused by the consumer requesting the services
                rendered at that moment.
            </p>
            <p>Amounts charged as the waiting period fee will also be visible
                on the consumer’s receipts.</p>
            <p>If users need any form assistance in regards this fee they can
                simply contact Apivoka support chat through email.</p>


</div>


        </div>
    );
}