import React from "react";
import "../../assets/css/construction.css"
import { Link } from "react-router-dom";

function Construction() {
  return <div className="constructionCover">
    
    <div className=" constructionContent">
      <div />
      <div><h1><strong><span>Constructio</span>n  Equipment</strong></h1>
        <p>
          It is evident that construction is a major player in the African economy, it is considered as one of the pillars on the economy however the construction industry has shown a slight decline in the industry.
          Through the means of our platform seeks to render solutions to the current state of the construction industry, it serves to be a catalyst that looks to ease the stress involved in the daily operations that will be time efficient, cost effective resulting to mass productivity on minimal costs.
        </p><p>  Companies  as the private individuals are enabled to list their equipment in possession onto our data base.
        Through our platform users have a direct easy access to request to hire any needed equipment listed without data base.

        </p><div class="col-md-12 text-center constructionButton">
          <button type="button"  className="btn " >List Equipment</button>
          <Link to="/Coming"> <button type="button" className="btn" >Register to Apivoka </button> </Link>
        </div>
        </div>
    </div>
  </div>;
}

export default React.memo(Construction);
