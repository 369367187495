import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Profile/prof1.css"


export const Prof2 = () => {
    return (
        <div className="container-fluid pro-cover">
            <SiList/>
            <div className="pro1-content">
            <h6>Changing of user’s email address</h6>
            <p>Users must note that all Apivoka newsletters and ride receipts
are mailed to the user’s email address linked to the user’s
account.</p>
            <p>To be able to check the current email address and change it,
                follow the steps below;<ul>
                    <li> Go to the main menu (top left corner)</li>
                    <li>Open the profile tab tap on the mail text field to edit it</li>
                    <li>Enter new email address and leave the page to save the
                        changes</li ></ul></p>
            <p>In case you have any problems with changing an email
                address, please contact our Apivoka WhatsApp support chat
                and we will provide you with a new email address of your
                choice</p>
            <p>
                Contact Apivoka support chat through email.
            </p>
</div>
        </div>
    );
}