import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick4 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Requesting a service</h6>
            
            <p>When requesting services on the Apivoka application, users
                can simply tap the menu (top left screen of the application);
                <ul>
                    <li>Click services.</li>
                    <li>Then select industry type</li>
                    <li>With the certain industry drop list select the type of service
                        or machinery needed.</li>
                    <li> The application will show current available services
                        providers within the users’ geographical locations.</li>
                    <li>The user will receive the rates of the service provider and
                        will have an option to accept the rates and request the
                        service needed.</li></ul></p>

            <p>Should users encounter a problem during the process of
                requesting a service then, please directly contact us for
                assistance. Contact Apivoka support chat through email.</p>

</div>
        </div>
    );
}