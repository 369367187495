import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/UPicktech/upick1.css"


export const UPick3 = () => {
    return (
        <div className="container-fluid upick-cover">
            <SiList/>
            <div className="upick-content">
            <h6>Using Apivoka safety toolkit</h6>
            <p>In case of emergency, in regards to health or any incident that
                happens then users can simply contact the Apivoka
                WhatsApp support centre or they can simply contact the local
                authorities within the geographical location.</p>
            <p>To contact authorities through the Apivoka application;
                <ul>
                <li>Tap on the green shield icon on the applications map.</li>
                        <li>The Apivoka safety toolkit page will open</li>
                            <li>Tap the emergency assist to contact local authorities
                                within the geographical location</li>
                                <li> Click call and explain the situation at hand.</li></ul></p>

            <p>Should users encounter problem trying to authorities through
                the Apivoka safety toolkit, please directly contact Apivoka
                support chat through email.</p>
            <p>And the Apivoka support team will contact authorities.</p>
</div>

        </div>
    );
}