import React from "react"
import { Link } from "react-router-dom";

import Arrow from "../../../assets/img/right-arrow.png"
import "../../../assets/css/UPicktech/upicktech.css"
import { SiList } from "components/Buttons/SiList";

export const UPicktech = () => {
    return (
        <div className="container-fluid upicktech-cover">
            <SiList/>

         <div className="container upick-content">  <h3>Using Apivoka</h3>

            

                <p>Services weren’t rendered<Link to="/services-weren’t-rendered"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Tipping the service provider<Link to="/tipping-service-provider"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Using Apivoka safety toolkit<Link to="/using-pickTech-safety-toolkit"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Requesting a service<Link to="/requesting-service"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Changing services<Link to="/changing-services"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Changing of location<Link to="/changing-location"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Calling the service provider<Link to="/calling-service-provider"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Having technical issue<Link to="/having-technical-issue"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Rating a Service<Link to="/rating-service"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Estimated time off arrival<Link to="/estimated-time-off-arrival"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Use Apivoka without a smartphone<Link to="/use-picktech-without-smartphone"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Reporting irregular behaviour<Link to="/reporting-accidents-irregular-behaviour"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Damage or cancellation fees<Link to="/damage-cancellation-fees"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
                <p>Cancelling services requested<Link to="/cancelling-services-requested"><img class="float-right" src={Arrow} alt="Arrow" /></Link></p>
            


            </div>
        </div>
    );
}