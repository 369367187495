
import React from "reactstrap"


import "../../assets/css/contacts.css";
import ContactSection from "./ContactSection";



function ContactUs(props) {
    return (
        <div className="contacts">
            <header>
                <h1><strong>CONTACT US</strong></h1>
            </header>
            <ContactSection />
           

        </div>
    );
}

export default ContactUs;
