import { SiList } from "components/Buttons/SiList";
import React from "react"
import "../../../assets/css/Signing/sign1.css"
export const Sign3 = () => {
    return (
        <div className="container-fluid s1-cover">
            <SiList/>
            <div className="s1-content">
            <h6>I need assistance with signing up.</h6>
            <p>The Apivoka team is 24 hours available to assist clients
                with any enquiries.</p>
            <p>Should clients have problems, please ensure that there&#39;s good internet
                connectivity, if Signing up through the Mobile Application ensure that the
                version of the application is updated.</p>
            <p>If the issue continues, then please contact the Apivoka support team.</p>
        </div>
        </div>
    );
}